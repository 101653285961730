import { editsSelectors, actions } from '@features/toolsPanel/store'
import { Box, Button } from '@mui/material'
import { EditListItem } from '../EditListItem'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React from 'react'

export const EditList = () => {
    const edits = useAppSelector(editsSelectors.getEditIds)
    const exists = useAppSelector(editsSelectors.isEditsExists)
    const dispatch = useAppDispatch()

    return (
        <>
            {exists && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: '16px',
                    }}
                >
                    {edits.map(id => (
                        <EditListItem key={id} id={id} />
                    ))}
                </Box>
            )}
            <Button fullWidth disabled={!exists} size="large" onClick={() => dispatch(actions.removeAllEdits())}>
                Delete all Edits
            </Button>
        </>
    )
}
