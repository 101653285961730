import * as React from 'react'
import { Box } from '@mui/system'
import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import { styled } from '@mui/system'
import scssVars from '@styles/vars.module.scss'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { uiSelectorsColorPicker, store, uiSelectors } from '@features/toolsPanel/store'
import { LumIcon } from '@shared/components/icons'
import { theme } from '@shared/components/providers'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { useEffectInfo } from '@features/toolsPanel/hooks/ctx'

type Option = {
    id: number | string
    title?: string
    divider?: boolean
    hidden?: boolean
}

export type EffectInfoInner = {
    name: string
    type: string
    select: Option[]
}

type Props = {
    item: EffectInfoInner
    field: UseFieldReturn
}

const { setColorPickerStatus } = store.colorPickerState.actions

export const WhiteBalanceSelect = ({ item, field: { field, watch } }: Props) => {
    const { toolId } = useEffectInfo()
    const dispatch = useAppDispatch()
    const pickerEnabled = useAppSelector(uiSelectorsColorPicker.getColorPicker)
    const parameterValue = useAppSelector(uiSelectors.getParameterState(toolId, field.name))

    const resetColorPippete = React.useCallback(() => {
        dispatch(setColorPickerStatus(false))
    }, [dispatch])

    React.useEffect(() => {
        const sub = watch(status => {
            if (status === 'reset') resetColorPippete()
        })

        return () => {
            resetColorPippete()
            sub.unsubscribe()
        }
    }, [dispatch, field, item.select, resetColorPippete, watch])

    const handleButtonClick = () => {
        dispatch(setColorPickerStatus(!pickerEnabled))
    }

    React.useEffect(() => {
        if (typeof parameterValue === 'undefined') return
        if (field.value === parameterValue) return
        field.onChange(parameterValue)
    }, [field, parameterValue])

    const handleChoose = (event: SelectChangeEvent<string>) => {
        field.onChange(event)
    }

    return (
        <Box>
            <Typography
                className="filter-title"
                color={'text.secondary'}
                sx={{
                    '& > sup': theme => ({ ...theme.typography.body2, color: 'text.secondary' }),
                }}
                component={'label'}
                variant={'h3'}
                htmlFor={field.name}
            >
                {item.name}
            </Typography>
            <Box sx={{ display: 'flex', m: '8px 0', alignItems: 'center' }}>
                <Select
                    fullWidth
                    disableUnderline
                    displayEmpty
                    IconComponent={() => {
                        return (
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    position: 'absolute',
                                    right: '2px',
                                }}
                            >
                                <LumIcon as="arrowDown" size={'1.25rem'} color={theme.palette.text.primary} />
                            </Box>
                        )
                    }}
                    value={field.value}
                    onChange={handleChoose}
                >
                    {item.select.map(item => {
                        return (
                            <MenuItem
                                key={item.id}
                                value={item.id}
                                sx={{
                                    display: item.hidden ? 'none' : 'block',
                                    color: 'secondary.contrastText',
                                    p: '6px 8px',
                                    '&:hover': {
                                        backgroundColor: theme => theme.palette.primary.dark,
                                    },
                                    '&.Mui-selected': {
                                        position: 'relative',
                                        backgroundColor: theme => theme.palette.primary.light,
                                    },
                                }}
                            >
                                {item.title}
                            </MenuItem>
                        )
                    })}
                </Select>
                <CustomButton onClick={handleButtonClick} className={pickerEnabled ? buttonUnstyledClasses.active : ''}>
                    {pickerEnabled ? (
                        <LumIcon
                            as="colorizePickerActive"
                            viewBox="0 0 16 16"
                            sx={{
                                color: scssVars.textPrimaryColor,
                            }}
                        />
                    ) : (
                        <LumIcon
                            as="colorizePicker"
                            viewBox="0 0 16 16"
                            sx={{
                                color: scssVars.textSecondaryColor,
                            }}
                        />
                    )}
                </CustomButton>
            </Box>
        </Box>
    )
}

const CustomButton = styled(ButtonUnstyled)`
    background-color: ${scssVars.primaryDarkColor};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    padding: 4px;
    border-radius: 4px;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;

    &:hover {
        background-color: ${scssVars.primaryLightColor};
    }

    &.${buttonUnstyledClasses.active} {
        background-color: ${scssVars.textActiveColor2};
    }
`
