import { Box, RadioGroup, FormControl } from '@mui/material'
import React, { useState } from 'react'

type Props = {
    children?: React.ReactNode
}

export const CurvesWrapper: React.FC<Props> = ({ children }) => {
    const [valueRadio, setValueRadio] = useState('RGB')

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueRadio((event.target as HTMLInputElement).value)
    }

    const handleResetRadio = () => {
        setValueRadio('RGB')
    }

    const childrenHavingOnOverFunction = React.Children.map(children, child => {
        return React.cloneElement(child as any, {
            valueRadio,
            handleRadioButton: handleResetRadio,
        })
    })

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Box
                sx={[
                    {
                        width: '216px',
                        height: '216px',
                        m: '30px 0 40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid rgba(255, 255, 255, 0.12)',
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.12)',
                        position: 'relative',
                        transform: 'translateX(-4px)',
                        zIndex: 1,
                        '&::before': {
                            content: '" "',
                            position: 'absolute',
                            left: '50%',
                            top: 0,
                            transform: 'translate(-50%, 0)',
                            width: 'calc(100% / 3)',
                            height: '100%',
                            borderLeft: '1px solid',
                            borderRight: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: 'linear-gradient(105.75deg, #01222D 11%, #0C172A 89%)',
                            zIndex: '-1',
                        },
                        '&::after': {
                            content: '" "',
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            transform: 'translate(0, -50%)',
                            width: '100%',
                            height: 'calc(100% / 3)',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderImageSlice: 1,
                            borderImageSource: 'linear-gradient(105.75deg, #01222D 11%, #0C172A 89%)',
                            zIndex: '-1',
                        },
                        '.active': {
                            pointerEvents: 'auto',
                            ellipse: {
                                opacity: 1,
                            },
                            '.curveSliderWr': {
                                opacity: 1,
                            },
                            canvas: {
                                zIndex: 1,
                            },
                        },
                    },
                ]}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%) rotate(-45deg)',
                        width: '138%',
                        height: '1px',
                        background: 'linear-gradient(105.75deg, #01222D 11%, #0C172A 89%)',
                        opacity: 0.7,
                        zIndex: 1,
                        pointerEvents: 'none',
                    }}
                ></Box>
                <FormControl sx={{ position: 'absolute', left: 0, top: '-40px', width: '100%' }}>
                    <RadioGroup sx={{ flexDirection: 'row' }} value={valueRadio} onChange={handleChangeRadio}>
                        {childrenHavingOnOverFunction}
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    )
}
