import { SubParameter, ToolId, Parameter } from '@domain/filters'
import { getEffectById } from '@features/toolsPanel/store/data/tools/selectors'
import { Box } from '@mui/system'
import { EffectsInfo } from '@shared/constants/effectsInfo'
import { EffectsLayoutInner } from '@shared/server/dto'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Slider } from '../Effects/Slider'
import { Checkbox } from '../Effects/Checkbox'
import { SkySelect } from '../Effects/SkySelect'
import { FilterSubMenu } from './FilterSubMenu'
import { useField } from '@features/toolsPanel/hooks/form'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import { EffectInfoInner, WhiteBalanceSelect } from '@features/toolsPanel/components/Effects/WhiteBalanceSelect'
import { SelectTabs } from '@features/toolsPanel/components/Effects/SelectTabs'
import { Curve } from '@features/toolsPanel/components/Tools/Light/Curve'
import { CurvesWrapper } from '@features/toolsPanel/components/Tools/Light/CurvesWrapper'
import { BWToggleButton } from '@features/toolsPanel/components/Effects/BWToggleButton'
import { DefaultTabs } from '@features/toolsPanel/components/Effects/DefaultTabs'
import { useAppSelector } from '@store/hooks'
import { isDisabledParameter } from '@features/toolsPanel/store/ui.selectors'
import { TabButtonIcons } from '@features/toolsPanel/components/Effects/TabButtonIcons'
import { EffectProvider } from '@features/toolsPanel/hooks/ctx'

type Props = {
    id: ToolId
}

export const EffectsFabricUI: FC<Props> = ({ id }) => {
    const items = useSelector(getEffectById(id))
    if (!items) return null
    const result = formLayout({ id, items })

    return <EffectProvider value={{ toolId: id }}>{result}</EffectProvider>
}

const formLayout = ({ id, items }: { id: ToolId; items: EffectsLayoutInner[] }) => {
    return items.map((effect, idx) => {
        let wrapper = ({ child }: { child: React.ReactNode; title?: string }) => child

        if (typeof effect.expandable === 'boolean' && effect.expandable) {
            // eslint-disable-next-line react/display-name
            wrapper = ({ child, title = '' }) => (
                <FilterSubMenu
                    key={`${effect.id}-${idx}`}
                    toolId={id}
                    name={getFormKey(id, `submenu:${idx}`)}
                    title={title}
                >
                    {child}
                </FilterSubMenu>
            )
        } else {
            return (
                <Box key={`${effect.id}-${idx}`} sx={{ p: '18px 32px 8px' }}>
                    {wrapper({ child: formChildren({ toolId: id, group: effect.items }), title: effect.title })}
                </Box>
            )
        }

        return wrapper({ child: formChildren({ toolId: id, group: effect.items }), title: effect.title })
    })
}

const formChildren = ({ toolId, group }: { toolId: ToolId; group: EffectsLayoutInner['items'] }) => {
    return group.map(items => {
        return items.map((item, idx) => {
            if (item.groups?.length) {
                return (
                    <GroupsParameterFabric
                        key={`${toolId}-${item.type}-${idx}`}
                        type={item.type}
                        groups={item.groups}
                        id={`${toolId}-${item.parameter}-${idx}`}
                        toolId={toolId}
                        tabButtonIcons={item.tabButtonIcons}
                    />
                )
            }
            return (
                <ParameterFabric
                    key={`${toolId}-${item.parameter}-${idx}`}
                    id={`${toolId}-${item.parameter}-${idx}`}
                    toolId={toolId}
                    parameter={item}
                />
            )
        })
    })
}

export const ParameterFabric: FC<{
    toolId: ToolId
    parameter: EffectsLayoutInner['items'][0][0]
    id?: string
    handleRadioButton?: () => void
    valueRadio?: string
}> = props => {
    const { parameter, valueRadio, handleRadioButton } = props
    const fieldProps = useField({ name: getFormKey(parameter.effect, parameter.parameter) })

    const parameterConfig = EffectsInfo[parameter.effect][parameter.parameter]
    const disableEffect = useAppSelector(isDisabledParameter(parameterConfig.disableCondition))

    if (parameter.type === 'for_this_photo') return null

    switch (parameterConfig?.type) {
        case 'TextureButton':
            return (
                <Box sx={{ p: '6px 0 16px' }}>
                    <SkySelect
                        label={parameterConfig.name}
                        watchChanges={fieldProps.watch}
                        onChange={fieldProps.field.onChange}
                    />
                </Box>
            )
        case 'Checkbox':
            return (
                <Box sx={{ mb: '18px' }}>
                    <Checkbox label={parameterConfig.name} fieldForm={fieldProps} disableEffect={disableEffect} />
                </Box>
            )
        case 'WhiteBalance':
            return (
                <Box sx={{ mb: '18px' }}>
                    <WhiteBalanceSelect field={fieldProps} item={parameterConfig as EffectInfoInner} />
                </Box>
            )
        case 'Curve':
            return (
                <Box sx={{ mb: '18px' }}>
                    <Curve
                        field={fieldProps}
                        item={parameterConfig as EffectInfoInner}
                        valueRadio={valueRadio}
                        handleRadioButton={handleRadioButton}
                    />
                </Box>
            )
        case 'BWToggleButton':
            return (
                <Box sx={{ mb: '12px' }}>
                    <BWToggleButton
                        label={parameterConfig.name}
                        fieldForm={fieldProps}
                        defaultValue={parameterConfig.defaultValue}
                    />
                </Box>
            )
        case 'TabButtonIcons':
            return <TabButtonIcons label={parameterConfig.name} disableEffect={disableEffect} />
        default:
            return (
                <Box sx={{ mb: '18px' }}>
                    <Slider config={parameterConfig} fieldForm={fieldProps} disableEffect={disableEffect} />
                </Box>
            )
    }
}

export const GroupsParameterFabric: FC<{
    type: string
    groups: SubParameter[]
    toolId: ToolId
    id?: string
    tabButtonIcons?: Parameter[]
}> = props => {
    const { groups, toolId, id, type, tabButtonIcons } = props
    const fieldProps = useField({ name: getFormKey(toolId, type) })

    const filteringGroups = groups.map(item => {
        return item.groupItems?.map((groupItem, index) => {
            return (
                <ParameterFabric
                    key={`${toolId}-${groupItem.parameter}-${index}`}
                    id={id}
                    toolId={toolId}
                    parameter={groupItem}
                />
            )
        })
    })

    const filteringTabButtonIcons = tabButtonIcons?.map((groupItem, index) => (
        <ParameterFabric
            key={`${toolId}-${groupItem.parameter}-${index}`}
            id={id}
            toolId={toolId}
            parameter={groupItem}
        />
    ))

    switch (type) {
        case 'curves':
            return <CurvesWrapper>{filteringGroups}</CurvesWrapper>
        case 'popUpTabs':
            return (
                <SelectTabs
                    groups={groups.map(group => ({ title: group.title, iconName: group.iconName }))}
                    defaultValue={groups.findIndex(group => group.selectedByDefault)}
                >
                    {filteringGroups}
                </SelectTabs>
            )
        case 'tabs':
            return (
                <DefaultTabs
                    groups={groups.map(group => ({ title: group.title }))}
                    defaultValue={groups.findIndex(group => group.selectedByDefault)}
                    tabIcons={filteringTabButtonIcons}
                    onChange={(num: number) => fieldProps.field.onChange(num)}
                >
                    {filteringGroups}
                </DefaultTabs>
            )
        default:
            return null
    }
}
