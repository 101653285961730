import { Box, FormControlLabel, Radio } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import { EffectInfoInner } from '@features/toolsPanel/components/Effects/WhiteBalanceSelect'
import { CubicSpline } from '@features/toolsPanel/services/CubicSpline'
import scssVars from '@styles/vars.module.scss'
import { SliderUnstyled, sliderUnstyledClasses } from '@mui/base'
import { styled } from '@mui/system'
import { Vector2DTuple, Vector2D } from '@shared/types'

type Props = {
    item: EffectInfoInner
    field: UseFieldReturn
    valueRadio?: string
    handleRadioButton?: () => void
}

type DrawDataProps = {
    context: CanvasRenderingContext2D
}

const DEFAULT_POINTS: Vector2D[] = [
    { x: 13, y: 13 },
    { x: 387, y: 387 },
]

const DEFAULT_COORDINATE_SLIDER: Vector2DTuple = [6.5, 193.5]
const splineConstructor = CubicSpline()

const width = 400
const height = 400
const scaleParameter = 2
// interpolated values in a buffer
const xSeriesInterpolated = new Float32Array(width).fill(0)
const ySeriesInterpolated = new Float32Array(width).fill(0)
// thickness of the curve
const curveThickness = 3
// radius of the control points
const controlPointRadius = 6.5 * scaleParameter

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 12,
    height: 12,
    backgroundColor: theme.palette.text.secondary,
    position: 'relative',
    '&.isRed': {
        backgroundColor: scssVars.customCurveRedColor,
    },
    '&.isGreen': {
        backgroundColor: scssVars.customCurveGreenColor,
    },
    '&.isBlue': {
        backgroundColor: scssVars.customCurveBlueColor,
    },
    '&:before': {
        content: '" "',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 22,
        height: 22,
        borderRadius: '50%',
        border: '2px solid #fff',
        opacity: 0,
    },
}))

const BpCheckedIcon = styled(BpIcon)({
    '&::before': {
        opacity: 1,
    },
})
const getSlope = (desiredRange: Vector2DTuple, uiRange: Vector2DTuple): number =>
    (desiredRange[0] - desiredRange[1]) / (uiRange[0] - uiRange[1])

const slope = getSlope([0, 1], [DEFAULT_POINTS[0].x, DEFAULT_POINTS[1].x])

export const Curve = ({
    item: { name },
    field: {
        field: { onChange: formOnChange },
        watch,
    },
    valueRadio,
    handleRadioButton,
}: Props) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)
    const onChange = useCallback(
        (curve: Vector2D[]) => {
            formOnChange(curve.map(({ x, y }) => ({ x: x * slope, y: y * slope })))
        },
        [formOnChange],
    )
    const screenRatio = window.devicePixelRatio

    const [points, setPoints] = useState<Vector2D[]>(DEFAULT_POINTS)
    const [value, setValue] = useState<number[]>(DEFAULT_COORDINATE_SLIDER)

    const [pointHoveredIndex, setPointHoveredIndex] = useState<number>(-1)
    const [pointGrabbedIndex, setPointGrabbedIndex] = useState<number>(-1)
    const [mouseDown, setMouseDown] = useState<boolean>(false)

    const [canvasClass, setCanvasClass] = useState<string>('')

    // color of the control points
    const controlPointColor = {
        idle: 'transparent',
        hovered: 'rgba(250, 250, 250, 1)',
        grabbed: 'rgba(250, 250, 250, 1)',
    }
    if (valueRadio === name) controlPointColor.idle = 'rgba(250, 250, 250, 1)'

    // style of the curve
    const curveColor = {
        idle: scssVars.customCurveWhiteColor,
        moving: 'rgba(250, 250, 250, 1)',
    }
    let rgbClass = ''
    if (name === 'Red') {
        rgbClass = 'isRed'
        curveColor.idle = scssVars.customCurveRedColor
    } else if (name === 'Green') {
        rgbClass = 'isGreen'
        curveColor.idle = scssVars.customCurveGreenColor
    } else if (name === 'Blue') {
        rgbClass = 'isBlue'
        curveColor.idle = scssVars.customCurveBlueColor
    }

    const drawData = useCallback(
        ({ context }: DrawDataProps) => {
            const curve = true
            const control = true
            context.clearRect(0, 0, width, height)
            const xSeries = points.map(point => point.x)
            const ySeries = points.map(point => point.y)
            const w = width
            const h = height

            if (!xSeries.length) return

            // drawing the curve
            if (curve) {
                context.beginPath()
                context.moveTo(xSeries[0] / screenRatio, (h - ySeries[0]) / screenRatio)

                const splineInterpolator = new splineConstructor(xSeries, ySeries)
                xSeriesInterpolated.fill(0)
                ySeriesInterpolated.fill(0)

                // before the first point (if not at the left of the canvas)
                for (let x = 0; x < Math.ceil(xSeries[0]); x++) {
                    let y = ySeries[0]
                    // copying the interpolated values in a buffer
                    xSeriesInterpolated[x] = x / w
                    ySeriesInterpolated[x] = y / h
                    // adjusting y for visual purpose
                    y = y < 0 ? 0.5 : y > h ? h - 0.5 : y
                    context.lineTo(x / screenRatio, (h - y) / screenRatio)
                }

                // between the first and the last point
                for (let x = Math.ceil(xSeries[0]); x < Math.ceil(xSeries[xSeries.length - 1]); x++) {
                    let y = splineInterpolator.interpolate(x)
                    // copying the interpolated values in a buffer
                    xSeriesInterpolated[x] = x / w
                    ySeriesInterpolated[x] = y / h
                    // adjusting y for visual purpose
                    y = y < 0 ? 0.5 : y > h ? h - 0.5 : y
                    context.lineTo(x / screenRatio, (h - y) / screenRatio)
                }

                // after the last point (if not at the right of the canvas)
                for (let x = Math.ceil(xSeries[xSeries.length - 1]); x < w; x++) {
                    let y = ySeries[ySeries.length - 1]
                    // copying the interpolated values in a buffer
                    xSeriesInterpolated[x] = x / w
                    ySeriesInterpolated[x] = y / h
                    // adjusting y for visual purpose
                    y = y < 0 ? 0.5 : y > h ? h - 0.5 : y
                    context.lineTo(x / screenRatio, (h - y) / screenRatio)
                }

                // context.strokeStyle = pointGrabbedIndex === -1 ? curveColor.idle : curveColor.moving
                context.strokeStyle = curveColor.idle
                context.lineWidth = curveThickness / screenRatio
                context.stroke()
                context.closePath()
            }

            // drawing the control points
            if (control) {
                // control points
                for (let i = 0; i < xSeries.length; i++) {
                    context.beginPath()
                    context.arc(
                        xSeries[i] / screenRatio,
                        (h - ySeries[i]) / screenRatio,
                        controlPointRadius / screenRatio,
                        0,
                        2 * Math.PI,
                    )
                    // drawing a point that is neither hovered nor grabbed
                    if (pointHoveredIndex === -1) {
                        context.fillStyle = controlPointColor.idle
                    } else {
                        // drawing a point that is hovered or grabbed
                        if (i === pointHoveredIndex) {
                            // the point is grabbed
                            if (mouseDown) {
                                context.fillStyle = controlPointColor.grabbed
                            }
                            // the point is hovered
                            else {
                                context.fillStyle = controlPointColor.hovered
                            }
                        } else {
                            context.fillStyle = controlPointColor.idle
                        }
                    }

                    context.fill()
                    context.closePath()
                }
            }
        },
        [
            controlPointColor.grabbed,
            controlPointColor.hovered,
            controlPointColor.idle,
            curveColor.idle,
            mouseDown,
            pointHoveredIndex,
            points,
            screenRatio,
        ],
    )
    const draw = useCallback(
        ({ context }: DrawDataProps) => {
            context.clearRect(0, 0, width, height)
            drawData({ context })
        },
        [drawData],
    )
    const getClosestFrom = useCallback(
        (p: Vector2D | null) => {
            if (!points.length || !p) return null

            let closestDistance = Infinity
            let closestPointIndex = -1

            for (let i = 0; i < points.length; i++) {
                const d = Math.sqrt(Math.pow(p.x - points[i].x, 2) + Math.pow(p.y - points[i].y, 2))

                if (d < closestDistance) {
                    closestDistance = d
                    closestPointIndex = i
                }
            }

            return {
                index: closestPointIndex,
                distance: closestDistance,
            }
        },
        [points],
    )
    const getClosestFromNoGrep = useCallback(
        (index: number, x: number, pointRadius: number) => {
            let closestDistance: number

            if (index === 0) {
                closestDistance = points[index + 1].x - (x + pointRadius)
            } else if (index === points.length - 1) {
                closestDistance = x - (points[index - 1].x + pointRadius)
            } else {
                if (x - points[index - 1].x < points[index + 1].x - x) {
                    closestDistance = x - (points[index - 1].x + pointRadius)
                } else {
                    closestDistance = points[index + 1].x - (x + pointRadius)
                }
            }

            return {
                distance: closestDistance,
            }
        },
        [points],
    )
    const getClosestFromClickXCoord = useCallback(
        (x: number, pointRadius: number) => {
            const newPoints = points.map(point => point.x)
            newPoints.push(x)
            newPoints.sort((x1, x2) => x1 - x2)
            const fakeIndex = newPoints.indexOf(x)
            let closestDistance: number
            if (fakeIndex === 0) {
                closestDistance = newPoints[fakeIndex + 1] - (x + pointRadius)
            } else if (fakeIndex === newPoints.length - 1) {
                closestDistance = x - (newPoints[fakeIndex - 1] + pointRadius)
            } else {
                if (x - newPoints[fakeIndex - 1] < newPoints[fakeIndex + 1] - x) {
                    closestDistance = x - (newPoints[fakeIndex - 1] + pointRadius)
                } else {
                    closestDistance = newPoints[fakeIndex + 1] - (x + pointRadius)
                }
            }
            return {
                distance: closestDistance,
            }
        },
        [points],
    )
    const updatePoint = useCallback(
        (index: number, p: Vector2D) => {
            let newIndex = index
            const newPoints = [...points]

            if (index >= 0 && index < points.length) {
                newPoints[index] = {
                    x: p.x,
                    y: p.y,
                }

                const thePointInArray = newPoints[index]
                setPoints(newPoints)
                onChange(newPoints)

                // the point may have changed its index
                newIndex = newPoints.indexOf(thePointInArray)
            }

            const newVal = [...value]
            newVal[0] = newPoints[0].x / scaleParameter
            newVal[1] = newPoints[newPoints.length - 1].x / scaleParameter
            setValue(() => newVal)

            return newIndex
        },
        [onChange, points, value],
    )
    const updatePointOnlyY = useCallback(
        (index: number, p: Vector2D) => {
            let newIndex = index
            const newPoints = [...points]

            if (index >= 0 && index < points.length) {
                const { x } = newPoints[index]

                newPoints[index] = {
                    x,
                    y: p.y,
                }

                const thePointInArray = newPoints[index]
                setPoints(newPoints)
                onChange(newPoints)

                // the point may have changed its index
                newIndex = newPoints.indexOf(thePointInArray)
            }

            return newIndex
        },
        [onChange, points],
    )
    const updatePointOnlyX = useCallback(
        (index: number, p: Vector2D) => {
            let newIndex = index
            const newPoints = [...points]

            if (index >= 0 && index < points.length) {
                const { y } = newPoints[index]

                newPoints[index] = {
                    x: p.x,
                    y,
                }

                const thePointInArray = newPoints[index]
                setPoints(newPoints)
                onChange(newPoints)

                // the point may have changed its index
                newIndex = newPoints.indexOf(thePointInArray)
            }

            const newVal = [...value]
            newVal[0] = newPoints[0].x / scaleParameter
            newVal[1] = newPoints[newPoints.length - 1].x / scaleParameter
            setValue(() => newVal)

            return newIndex
        },
        [onChange, points, value],
    )
    const removePoint = useCallback(
        (index: number) => {
            const newPoints = [...points]

            if (index === 0) {
                updatePoint(index, DEFAULT_POINTS[index])
                return
            }
            if (index === points.length - 1) {
                updatePoint(index, DEFAULT_POINTS[1])
                return
            }

            newPoints.splice(index, 1)
            setPoints(newPoints)
            onChange(newPoints)
        },
        [onChange, points, updatePoint],
    )
    const addPoint = useCallback(
        (p: { x: number; y: number }) => {
            let newIndex = null
            if (p.x < points[0].x || p.x > points[points.length - 1].x) return
            const newPoints = [...points]
            newPoints.push(p)
            newPoints.sort((p1, p2) => p1.x - p2.x)
            newIndex = newPoints.indexOf(p)
            setPoints(newPoints)
            onChange(newPoints)

            return newIndex
        },
        [onChange, points],
    )

    const reset = useCallback(
        (context: CanvasRenderingContext2D) => {
            if (context) {
                setPoints(DEFAULT_POINTS)
                setValue(DEFAULT_COORDINATE_SLIDER)
                draw({ context })
                setCanvasClass('')
            }
        },
        [draw],
    )

    useEffect(() => {
        const sub = watch(status => {
            if (status === 'reset' && context) {
                reset(context)
                handleRadioButton && handleRadioButton()
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [context, handleRadioButton, reset, watch])

    //set context
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas) return
        setContext(canvas.getContext('2d'))
    }, [])

    //set canvas
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas || !context) return
        canvas.width = width
        canvas.height = height
        context.scale(screenRatio, screenRatio)

        draw({ context })
    }, [context, draw, screenRatio])

    //canvas events: move, down, up
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas || !context) return

        const handleMouseMove = (event: MouseEvent): void => {
            // check what control point is the closest from the pointer position
            const rect = canvas.getBoundingClientRect()
            const mouse = {
                x: (event.clientX - rect.left) * scaleParameter,
                y: (height / scaleParameter - (event.clientY - rect.top)) * scaleParameter,
            }
            const closestPointInfo = getClosestFrom(mouse)
            if (!closestPointInfo) return

            if (
                pointGrabbedIndex !== -1 &&
                getClosestFromNoGrep(pointGrabbedIndex, mouse.x, controlPointRadius).distance <= 0
            ) {
                draw({ context })
                if (mouse.y < 13 || mouse.y > 387) {
                    return
                } else {
                    setPointGrabbedIndex(updatePointOnlyY(pointGrabbedIndex, mouse))
                }
                setPointHoveredIndex(pointGrabbedIndex)
            } else {
                // no point is currently grabbed
                if (pointGrabbedIndex === -1) {
                    // the pointer hovers a point
                    if (closestPointInfo.distance <= controlPointRadius) {
                        setPointHoveredIndex(closestPointInfo.index)
                        canvas.style.cursor = 'grabbing'
                    }
                    // the pointer does not hover a point
                    else {
                        // ... but maybe it used to hover a point, in this case we want to redraw
                        // to change back the color to idle mode
                        canvas.style.cursor = 'default'

                        let mustRedraw = false
                        if (pointHoveredIndex !== -1) {
                            mustRedraw = true
                        }
                        setPointHoveredIndex(-1)
                        if (mustRedraw) draw({ context })
                    }
                }
                // a point is grabbed
                else {
                    if (mouse.x < 13 || mouse.x > 387) {
                        if (mouse.y < 13 || mouse.y > 387) {
                            return
                        } else {
                            setPointGrabbedIndex(updatePointOnlyY(pointGrabbedIndex, mouse))
                        }
                    } else if (mouse.y < 13 || mouse.y > 387) {
                        if (mouse.x < 13 || mouse.x > 387) {
                            return
                        } else {
                            setPointGrabbedIndex(updatePointOnlyX(pointGrabbedIndex, mouse))
                        }
                    } else {
                        setPointGrabbedIndex(updatePoint(pointGrabbedIndex, mouse))
                    }
                    setPointHoveredIndex(pointGrabbedIndex)
                }

                // reduce useless drawing
                if (pointHoveredIndex !== -1 || pointGrabbedIndex !== -1) {
                    draw({ context })
                    if (!canvasClass) setCanvasClass('showed')
                }
            }
        }

        const handleMouseDown = () => {
            setMouseDown(true)
            pointHoveredIndex !== -1 && setPointGrabbedIndex(pointHoveredIndex)
        }

        const handleMouseUp = () => {
            setMouseDown(false)
            setPointGrabbedIndex(-1)
            draw({ context })
        }

        canvas.addEventListener('mousemove', handleMouseMove)
        canvas.addEventListener('mousedown', handleMouseDown)
        canvas.addEventListener('mouseup', handleMouseUp)
        return () => {
            canvas.removeEventListener('mousemove', handleMouseMove)
            canvas.removeEventListener('mousedown', handleMouseDown)
            canvas.removeEventListener('mouseup', handleMouseUp)
        }
    }, [
        canvasClass,
        context,
        draw,
        getClosestFrom,
        getClosestFromNoGrep,
        pointGrabbedIndex,
        pointHoveredIndex,
        points,
        updatePoint,
        updatePointOnlyX,
        updatePointOnlyY,
    ])

    //canvas click event
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas || !context) return
        const handleMouseClick = (event: MouseEvent): void => {
            const rect = canvas.getBoundingClientRect()
            if (event.detail > 1) {
                if (pointHoveredIndex !== -1) {
                    removePoint(pointHoveredIndex)
                    draw({ context })
                    setPointGrabbedIndex(-1)
                    setPointHoveredIndex(-1)
                }
            } else {
                if (pointHoveredIndex === -1) {
                    const x = (event.clientX - rect.left) * scaleParameter
                    const y = (height / scaleParameter - (event.clientY - rect.top)) * scaleParameter
                    const detectedClosestPoint = getClosestFromClickXCoord(x, controlPointRadius).distance
                    if (points.length >= 10 || detectedClosestPoint <= 0) return
                    addPoint({ x, y })
                    draw({ context })
                }
            }
        }
        canvas.addEventListener('click', handleMouseClick)
        return () => {
            canvas.removeEventListener('click', handleMouseClick)
        }
    }, [addPoint, context, draw, getClosestFromClickXCoord, pointHoveredIndex, points, removePoint])

    //handle range slider
    const handleChange = useCallback(
        (event: Event, newValue: number | number[], activeThumb: number) => {
            const current = newValue as number[]
            if (!Array.isArray(newValue)) return

            if (activeThumb === 0) {
                if (
                    current[1] - current[0] < 18 ||
                    getClosestFromNoGrep(0, current[0] * scaleParameter, controlPointRadius).distance <= 0 ||
                    current[0] < 6.5
                ) {
                    return
                } else {
                    setValue(current)
                    // const value = Math.round((current[0] / width) * 1000) / 1000
                    const value = current[0]
                    const newPoints = [...points]
                    const { y } = newPoints[0]
                    newPoints[0] = {
                        x: value * scaleParameter,
                        y,
                    }
                    setPoints(newPoints)
                    onChange(newPoints)
                }
                // } else if (activeThumb === 1) {
                //     if (current[1] - current[0] < 18) {
                //         setValue([current[1] - 18, current[1], current[2]])
                //     } else if (current[2] - current[1] < 18) {
                //         setValue([current[0], current[1], current[1] + 18])
                //     } else {
                //         setValue(current)
                //     }
            } else if (activeThumb === 1) {
                if (
                    current[1] - current[0] < 18 ||
                    getClosestFromNoGrep(
                        points.length - 1,
                        current[1] * scaleParameter,
                        controlPointRadius / scaleParameter,
                    ).distance <= 0 ||
                    current[1] > 193.5
                ) {
                    return
                } else {
                    setValue(current)
                    // const value = Math.round((current[1] / width) * 1000) / 1000
                    const value = current[1]
                    const newPoints = [...points]
                    const { y } = newPoints[newPoints.length - 1]
                    newPoints[newPoints.length - 1] = {
                        x: value * scaleParameter,
                        y,
                    }
                    setPoints(newPoints)
                    onChange(newPoints)
                }
            } else {
                setValue(current)
            }
        },
        [getClosestFromNoGrep, onChange, points],
    )

    return (
        <>
            <FormControlLabel
                value={name}
                className={rgbClass}
                sx={{ m: '0 0 14px' }}
                control={
                    <Radio
                        sx={{ '&:hover': { background: 'none' } }}
                        size="small"
                        checkedIcon={<BpCheckedIcon className={rgbClass} />}
                        icon={<BpIcon className={rgbClass} />}
                    />
                }
                label=""
            />
            <Box
                className={`${valueRadio === name ? 'active' : ''}`}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    p: '40px 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    pointerEvents: 'none',
                    '.curveSliderWr': {
                        opacity: 0,
                    },
                    canvas: {
                        opacity: 0,
                        position: 'relative',
                        zIndex: 2,
                        '&.showed': {
                            opacity: 0.5,
                        },
                    },

                    '&.active': {
                        canvas: {
                            opacity: 1,
                            zIndex: 5,
                        },
                    },
                }}
            >
                <canvas
                    ref={canvasRef}
                    className={canvasClass}
                    style={{
                        width: `${width / scaleParameter}px`,
                        height: `${height / scaleParameter}px`,
                        left: 0,
                        top: 0,
                        margin: '7px auto 0',
                        border: 'none',
                        overflow: 'hidden',
                    }}
                ></canvas>
                <Box
                    className="curveSliderWr"
                    sx={{ p: '20px 0', position: 'absolute', width: '200px', top: '100%', left: '7px' }}
                >
                    <StyledSlider value={value} disableSwap max={200} min={0} onChange={handleChange} />
                </Box>
            </Box>
        </>
    )
}

const StyledSlider = styled(SliderUnstyled)(
    () => `
    height: 3px;
    width: 100%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 10px 0;
    
    & .${sliderUnstyledClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.16);
    }
  
    & .${sliderUnstyledClasses.track} {
      display: block;
      position: absolute;
      height: 3px;
      border-radius: 2px;
      background: transparent;
    }
  
    & .${sliderUnstyledClasses.thumb} {
      position: absolute;
      width: 13px;
      height: 13px;
      margin-left: -6.5px;
      margin-top: -5.5px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      background-color: rgba(250, 250, 250, 1);
      
      &.${sliderUnstyledClasses.focusVisible},
      &.${sliderUnstyledClasses.active} {
        box-shadow: 0 0 0 0.25rem #001927;
      }
    }
  `,
)
