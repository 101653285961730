import { ToolId } from '@domain/filters'
import { useField } from '@features/toolsPanel/hooks/form'
import { Box, SxProps } from '@mui/material'
import { get } from 'lodash-es'
import React, { FC, KeyboardEventHandler, useCallback } from 'react'
import { Layout, EffectInfo, EffectInfoInner } from './constants'
import { RatioGroup } from './RatioGroup'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import { CropPayload, onCropPanelClick } from '@features/toolsPanel/store/actions'
import { useAppDispatch } from '@store/hooks'
import { CropButton } from './CropButton'
import { ButtonGroup } from './ButtonGroup'
import { onSubmitByKey } from '@features/renderer/store/actions'
import { useServices } from '@shared/components/providers/services'
import { CropAnalytics } from '@features/toolsPanel/constants'

type Props = {
    id: ToolId
}

const boxSX: SxProps = { mb: '16px', '&:last-child': { mb: 0 } }
export const Crop: FC<Props> = () => {
    const dispatch = useAppDispatch()

    const onKeySubmit = useCallback<KeyboardEventHandler<HTMLDivElement>>(
        event => event.key === 'Enter' && dispatch(onSubmitByKey()),
        [dispatch],
    )
    return (
        <Box tabIndex={0} sx={{ p: '20px 32px 28px', outline: 'none' }} onKeyDown={onKeySubmit}>
            {Layout.map(group =>
                group.items.map((item, idx) => (
                    <Box key={`${group.id}-${idx}`} sx={boxSX}>
                        {item.map(_item => (
                            <EffectFabrik key={`${_item.effect}-${_item.parameter}`} item={_item} />
                        ))}
                    </Box>
                )),
            )}
        </Box>
    )
}

const EffectFabrik: FC<{ item: typeof Layout[number]['items'][number][number] }> = ({ item }) => {
    const dataInstance = get(EffectInfo, [item.effect, item.parameter], {}) as EffectInfoInner
    const fieldProps = useField({ name: getFormKey(item.effect, item.parameter) })
    const disp = useAppDispatch()
    const { analytics } = useServices()
    const onClick = useCallback(
        (elType?: CropPayload['element']) => () => {
            // Just to make form 'dirty'
            fieldProps.field.onChange(fieldProps.field.value)
            if (elType) {
                const analyticType = CropAnalytics[elType]
                !!analyticType && analytics.cropUsage(analyticType)
                disp(onCropPanelClick({ element: elType }))
            }
        },
        [analytics, disp, fieldProps.field],
    )

    switch (dataInstance.type) {
        case 'select':
            return <RatioGroup field={fieldProps} item={dataInstance} />
        case 'button':
            return <CropButton parameter={item.parameter} onClick={onClick} item={dataInstance} field={fieldProps} />
        case 'buttongroup':
            return <ButtonGroup onClick={onClick} parameter={item.parameter} item={dataInstance} field={fieldProps} />
        default:
            return null
    }
}
