import { CategoryId, ToolId } from '@domain/filters'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { FC, startTransition, useCallback, useEffect } from 'react'
import { EffectsFabricUI } from '../EffectsFabricUI'
import { FilterWrapper } from '../EffectsFabricUI/FilterWrapper'
import { ToolColorByCategory } from '../../models'
import { LumIcon } from '@shared/components/icons'
import { getToolById, getEffectDefaultValue } from '../../store/data/tools/selectors'
import { useForm, Provider } from '@features/toolsPanel/hooks/form'
import { Crop } from '../Tools/Crop'
import { toolsState } from '@features/toolsPanel/store/ui.store'
import * as uiSelectors from '@features/toolsPanel/store/ui.selectors'
import { getFormKey } from '@features/toolsPanel/utils/getFormKey'
import {
    onDisableCurrentEffect,
    onResetEffectForm,
    onResetEffectInMipl,
    onResetEffectInUI,
} from '@features/toolsPanel/store/actions'
import { onCropChanged } from '@features/renderer/store/actions'
import { useActionsListener } from '@shared/hooks'
import { isEmpty as _isEmpty } from 'lodash-es'
import { toolIdToicon } from '@features/toolsPanel/utils/toolIdToicon'
import { useServices } from '@shared/components/providers/services'

type Props = {
    id: ToolId
    category: CategoryId
}

export const ToolsFabricUI: FC<Props> = props => {
    const { id } = props
    const element = getElement({ id })

    return element ? <Wrapper {...props}>{element}</Wrapper> : null
}

const getElement: FC<Omit<Props, 'category'>> = props => {
    switch (props.id) {
        case 'SkyReplacementAdjustmentLayer':
            return EffectsFabricUI({ id: props.id })
        case 'MPCropToolFilter':
            return <Crop id={props.id} />
        case 'DefaultEffects':
            return EffectsFabricUI({ id: props.id })
        case 'AIEnhanceAdjustmentLayer':
            return EffectsFabricUI({ id: props.id })
        // Temporary disabled
        // case 'AIStructureEffect':
        //     return EffectsFabricUI({ id: props.id })
        case 'ExtendedColorAdjustmentLayer':
            return EffectsFabricUI({ id: props.id })
        case 'BWChannels_v1_Effect':
            return EffectsFabricUI({ id: props.id })
        default:
            return null
    }
}

const Wrapper: FC<Props & { children: React.ReactNode }> = ({ id, category, children }) => {
    const tool = useAppSelector(getToolById(id))
    const defaultValues = getEffectDefaultValue(id)
    const isCropTool = id === 'MPCropToolFilter'
    const wrapperKey = getFormKey(id, 'wrapper')
    const { form } = useForm({ defaultValues })
    const dispatch = useAppDispatch()
    const isOpen = useAppSelector(uiSelectors.isToolOpen(id))
    const { dispatchClick } = useAnalytics(id)

    const resetToolUI = useCallback(() => {
        form.reset()
        dispatch(onResetEffectInUI(id))
    }, [dispatch, form, id])

    useActionsListener(() => {
        if (!isCropTool) return
        if (!form.formState.isDirty) {
            form.setValue(wrapperKey, isOpen, { shouldDirty: true })
        }
    }, onCropChanged)

    useActionsListener(action => {
        const { payload } = action as ReturnType<typeof onResetEffectForm>
        if (payload !== id) return
        setTimeout(resetToolUI)
    }, onResetEffectForm)

    useEffect(() => {
        const sub = form.watch((values, info) => {
            if (_isEmpty(values)) return
            startTransition(() => {
                dispatch(
                    toolsState.actions.updateFormState({
                        values: { ...values },
                        meta: { name: info.name, id },
                    }),
                )
            })
        })
        return () => {
            sub.unsubscribe()
        }
    }, [form, dispatch, id])

    useEffect(() => {
        if (!isOpen) setTimeout(resetToolUI)
    }, [isOpen, resetToolUI])

    const onOpenMemo = useCallback(() => {
        dispatchClick()
        dispatch(
            toolsState.actions.updateFormState({
                values: { [wrapperKey]: !isOpen },
                meta: { name: wrapperKey, id },
            }),
        )
    }, [dispatchClick, dispatch, wrapperKey, isOpen, id])

    const onCompare = useCallback(
        (val: boolean) => {
            dispatch(onDisableCurrentEffect({ disable: val, tool: id }))
        },
        [dispatch, id],
    )

    const onResetMemo = useCallback(() => {
        resetToolUI()
        dispatch(onResetEffectInMipl(id))
        dispatch(
            toolsState.actions.updateFormState({
                values: { [wrapperKey]: isOpen },
                meta: { name: wrapperKey, id },
            }),
        )
    }, [dispatch, id, isOpen, resetToolUI, wrapperKey])

    const renderIcon = useCallback(
        (props: { color: string }) => (
            <LumIcon as={toolIdToicon[id]} size={'1.25rem'} sx={{ m: '0 4px 0 0' }} {...props} />
        ),
        [id],
    )

    return (
        <Provider {...form}>
            <FilterWrapper
                testId={id}
                isApplied={form.formState.isDirty}
                Icon={renderIcon}
                title={tool.title}
                color={colorMapping[category]}
                onResetClick={onResetMemo}
                onOpen={onOpenMemo}
                onCompare={onCompare}
                isOpen={isOpen}
                isCompareEnabled={!isCropTool}
            >
                {children}
            </FilterWrapper>
        </Provider>
    )
}

const useAnalytics = (id: ToolId | undefined) => {
    const { analytics } = useServices()
    const dispatchClick = useCallback(() => {
        switch (id) {
            case 'MPCropToolFilter':
                return analytics.cropUsage('click')
            case 'AIEnhanceAdjustmentLayer':
                return analytics.enhanceUsage('click')
            case 'DefaultEffects':
                return analytics.lightUsage('click')
            case 'ExtendedColorAdjustmentLayer':
                return analytics.colorsUsage('click')
            case 'BWChannels_v1_Effect':
                return analytics.blackAndWhiteUsage('click')
            default:
                return Promise.resolve()
        }
    }, [analytics, id])

    return {
        dispatchClick,
    }
}

const colorMapping: { [key in CategoryId]: ToolColorByCategory } = {
    '': 'fifth',
    Essentials: 'primary',
    Creative: 'secondary',
    Pro: 'secondary',
    Portrait: 'secondary',
}
