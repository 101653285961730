import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
    useController,
    useFormContext as uFormContext,
    useForm as uForm,
    FormProvider,
    ControllerRenderProps,
    FieldValues,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form'
import { Observable, Subject } from 'rxjs'
import { FormEvents } from '../models'
import { FieldName } from '../utils/getFormKey'

export type UseFieldReturn = {
    field: ControllerRenderProps<FieldValues, FieldName>
    watch: Observable<FormEvents>['subscribe']
}

export const useFormContext = uFormContext

type RT = { name: FieldName; defaultValue?: unknown }
const subject = new Subject<FormEvents>()
const resetFields = () => {
    subject.next('reset')
}
export const useField = ({ name, defaultValue }: RT): UseFieldReturn => {
    const refValue = useRef(undefined)
    const { control } = uFormContext()
    const { field } = useController({
        name,
        control,
        defaultValue,
    })

    useEffect(() => {
        const isDirtyCustomField = typeof refValue.current !== 'undefined' && typeof field.value === 'undefined'
        if (isDirtyCustomField) resetFields()

        refValue.current = field.value
    }, [field.value])

    return { field, watch: subject.subscribe.bind(subject) }
}

export type UseFormReturnType<T> = {
    form: UseFormReturn<T>
    resetFields: () => void
}

export function useForm<T = FieldValues>(props: UseFormProps<T, any> = { mode: 'onChange' }): UseFormReturnType<T> {
    const formProps = uForm(props)

    return { form: formProps, resetFields }
}

export const Provider = FormProvider
