import React from 'react'
import { Box } from '@mui/material'
import dynamic from 'next/dynamic'
import { useAppSelector } from '@store/hooks'
import { selectors, uiSelectors } from '@features/renderer/store'
import { GeneralToolbar } from '@features/toolsPanel'

const NoSSRCanvas = dynamic<unknown>(() => import('./renderer').then(def => def.Renderer), {
    ssr: false,
})

const rightPadding = '36px'
export const Renderer = () => {
    const fileAdded = useAppSelector(selectors.isImageInfoFetched)
    const cropIsEnabled = useAppSelector(uiSelectors.cropIsEnabled)
    const imageState = useAppSelector(uiSelectors.getCanvasAnimationState)
    const enableToolbar = fileAdded && !cropIsEnabled

    return (
        <Box
            aria-label="canvas-wrapper"
            sx={[
                {
                    width: '100%',
                    height: '100%',
                    p: `12px ${rightPadding} 0 0`,
                    transition: 'transform 600ms ease, opacity 600ms ease',
                    position: 'relative',
                },
                !fileAdded && {
                    transform: 'scale(.5)',
                },
                imageState === 'animate-in' && { transform: 'scale(.3)', opacity: 0.01 },
            ]}
        >
            <NoSSRCanvas />
            {enableToolbar && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '16px',
                        right: `calc(50% + ${rightPadding})`,
                        transform: 'translate(50%, -50%)',
                    }}
                >
                    <GeneralToolbar />
                </Box>
            )}
        </Box>
    )
}
