import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { UseFormRegisterReturn } from 'react-hook-form'
import { CloseRounded } from '@mui/icons-material'
import { getByTestId } from '@testing-library/react'
import { getTestId } from '@shared/testing'

type Props = {
    onClose: () => void
    onSubmit: () => void
    open: boolean
    aRegister: UseFormRegisterReturn
    bRegister: UseFormRegisterReturn
    error: string | undefined
}
const stopPropagation: React.KeyboardEventHandler<HTMLFormElement> = evt => evt.stopPropagation()

export const RatioDialog = ({ onClose, onSubmit, open, aRegister, bRegister, error }: Props) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <Box component={'form'} sx={{ minWidth: '576px' }} onSubmit={onSubmit} onKeyDown={stopPropagation}>
                <DialogTitle sx={{ padding: '18px 20px 22px' }}>
                    <Typography variant="h1" component={'span'}>
                        Enter custom aspect ratio
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ padding: '0 20px' }}>
                    <Box sx={{ display: 'flex', alignContent: 'stretch' }}>
                        <TextField
                            inputProps={getTestId('ratio-width')}
                            type="number"
                            {...aRegister}
                            autoFocus
                            error={!!error}
                            helperText={error ?? ''}
                            sx={{ flexGrow: 1 }}
                        />
                        <Box sx={{ alignSelf: 'flex-start', padding: '18px 10px' }}>
                            <CloseRounded sx={{ fontSize: '1rem' }} />
                        </Box>
                        <TextField
                            inputProps={getTestId('ratio-height')}
                            error={!!error}
                            type="number"
                            {...bRegister}
                            sx={{ flexGrow: 1 }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: '18px 20px' }}>
                    <Button variant="text" onClick={onClose}>
                        Close
                    </Button>
                    <Button {...getTestId('cutom-ratio-submit')} type="submit">
                        Apply
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
