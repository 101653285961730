import { TYPES } from '@shared/components/icons'

export const toolIdToicon: { [key: string]: keyof typeof TYPES } = {
    SkyReplacementAdjustmentLayer: 'tool_sky',
    MPCropToolFilter: 'tool_crop',
    DefaultEffects: 'tool_light',
    AIEnhanceAdjustmentLayer: 'tool_enhance',
    ExtendedColorAdjustmentLayer: 'toolColorMUI',
    AIStructureEffect: 'toolStructure',
    BWChannels_v1_Effect: 'toolBlackWhite',
}
