import React, { useCallback, useEffect, useState } from 'react'
import { EffectInfoInner } from '../constants'
import { UseFieldReturn, useForm } from '@features/toolsPanel/hooks/form'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { LabelGroup } from '../LabelGroup'
import { cropState } from '@features/toolsPanel/store/ui/crop/store'
import { uniqueId } from 'lodash-es'
import { FieldValues } from 'react-hook-form'
import { uiSelectorsCrop } from '@features/toolsPanel/store'
import { RatioDialog } from './RatioDialog'
import { gcdNumbers } from '@features/toolsPanel/utils/gcdNumbers'
import { Option } from './types'
import { RatioSelect } from './RatioSelect'
import { transposeRatioKey, formRatioKey } from '@features/toolsPanel/utils/formRatioKey'
import { RatioOptions } from '@features/toolsPanel/constants'
import { useServices } from '@shared/components/providers/services'

const { updateSetRatio } = cropState.actions
const idOriginalRatio = RatioOptions[2].id
const idTransposedRatio = RatioOptions[3].id
const MAX_RATIO_RELATION = 10

export const RatioGroup: React.FC<{
    item: EffectInfoInner
    field: UseFieldReturn
}> = ({ field, item }) => {
    const { field: formField } = field
    const { onSubmit, onRatioChange, onTranspose, open, options, handleClose, aField, bField, error } = useRatio({
        formField,
    })

    return (
        <>
            <LabelGroup>{item.name}</LabelGroup>
            <RatioSelect item={item} field={field} options={options} onChange={onRatioChange} onClick={onTranspose} />
            <RatioDialog
                onClose={handleClose}
                onSubmit={onSubmit}
                open={open}
                aRegister={aField}
                bRegister={bField}
                error={error}
            />
        </>
    )
}

type useProps = {
    formField: UseFieldReturn['field']
}
const useRatio = ({ formField }: useProps) => {
    const dispatch = useAppDispatch()
    const [newOptions, setOptions] = useState<Option[]>(RatioOptions)
    const [open, setOpen] = React.useState(false)
    const ratio = useAppSelector(uiSelectorsCrop.getCropRatio)
    const currentRatioId = useAppSelector(uiSelectorsCrop.getCropRatioKey)
    const allUsedOptions = useAppSelector(uiSelectorsCrop.getRatioOptions)
    const { analytics } = useServices()
    const { form: customRatioForm } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            width: 1,
            height: 1,
        },
    })

    const handleRatio = useCallback(
        (props: { id: string | number }) => {
            formField.onChange(props)
            analytics.cropUsage('ratio', `${props.id}`)
        },
        [analytics, formField],
    )

    const handleClickOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleSubmit = useCallback(
        ({ width: _width, height: _height }: FieldValues) => {
            const widthNum = Number(_width)
            const heightNum = Number(_height)

            if (MAX_RATIO_RELATION < Math.max(widthNum, heightNum) / Math.min(widthNum, heightNum)) {
                return customRatioForm.setError('width', {
                    message: 'The ratio of the sides is not proportional',
                    type: 'custom',
                })
            }
            const divisor = gcdNumbers(widthNum, heightNum)
            const width = widthNum / divisor
            const height = heightNum / divisor
            const id = formRatioKey(width, height)
            const newOp: Option[] = [
                ...newOptions,
                ...(newOptions.length === RatioOptions.length ? [{ id: uniqueId(), divider: true }] : []),
                { id, title: `${width}:${height}` },
            ]
            dispatch(
                updateSetRatio({
                    key: id,
                    ratio: { width, height },
                }),
            )
            setOptions(newOp)
            handleRatio({ id })
            handleClose()
        },
        [customRatioForm, dispatch, handleClose, handleRatio, newOptions],
    )

    const onRatioChange = useCallback(
        (prop: { id: string | number }) => {
            if (prop.id === 'custom') {
                handleClickOpen()
                return
            }
            handleRatio(prop)
        },
        [handleClickOpen, handleRatio],
    )

    const onTranspose = useCallback(() => {
        if (currentRatioId === idTransposedRatio) return handleRatio({ id: idOriginalRatio })
        if (currentRatioId === 'free' || currentRatioId === 'custom' || currentRatioId === idOriginalRatio) {
            return handleRatio({ id: idTransposedRatio })
        }

        const id = transposeRatioKey(currentRatioId)
        dispatch(
            updateSetRatio({
                key: id,
                ratio: {
                    width: Number(ratio.height),
                    height: Number(ratio.width),
                },
            }),
        )
        handleRatio({ id })
        setOptions(
            newOptions.map(option => ({
                ...option,
                id: option.id === currentRatioId ? id : option.id,
            })) as Option[],
        )
    }, [currentRatioId, dispatch, handleRatio, newOptions, ratio.height, ratio.width])

    useEffect(() => {
        if (!!formField.value?.id && currentRatioId && formField.value.id !== currentRatioId) {
            const size = allUsedOptions[currentRatioId]
            if (!size) return

            const isCustomOrTransposed = !newOptions.find(option => option.id === currentRatioId)
            if (isCustomOrTransposed) {
                const transposedRatioId = transposeRatioKey(currentRatioId)
                const isTransposed = !!newOptions.find(option => option.id === transposedRatioId)

                const opts = isTransposed
                    ? (newOptions.map(option => ({
                          ...option,
                          id: option.id === transposedRatioId ? currentRatioId : option.id,
                      })) as Option[])
                    : [
                          ...newOptions,
                          { id: uniqueId(), divider: true },
                          { id: currentRatioId, title: `${size.width}:${size.height}` },
                      ]
                setOptions(opts)
            }
            handleRatio({ id: currentRatioId })
        }
    }, [allUsedOptions, currentRatioId, formField, formField.value, handleRatio, newOptions])

    return {
        onSubmit: customRatioForm.handleSubmit(handleSubmit),
        handleClose,
        onRatioChange,
        onTranspose,
        open,
        options: newOptions,
        aField: customRatioForm.register('width', { required: true }),
        bField: customRatioForm.register('height', { required: true }),
        error: customRatioForm.formState.errors['width']?.message,
    }
}
