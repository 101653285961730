import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import { InputUnstyled, SliderUnstyled, sliderUnstyledClasses, inputUnstyledClasses } from '@mui/base'
import { Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { isNaN } from 'lodash-es'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './index.module.scss'
import { theme } from '@shared/components/providers'
import cls from 'classnames'
import { ParameterConfig, VISUAL_DEFAULT_RANGE } from '@shared/constants/effectsInfo'
import { useAppSelector } from '@store/hooks'
import { uiSelectors } from '@features/toolsPanel/store'
import { useEffectInfo } from '@features/toolsPanel/hooks/ctx'
import { getTestId } from '@shared/testing'

type Props = {
    defaultValue?: number
    config: ParameterConfig
    fieldForm: UseFieldReturn
    disableEffect: boolean
}

export const Slider = (props: Props) => {
    const {
        id,
        label,
        ariaLabel,
        disableEffect,
        defaultValue,
        min,
        max,
        step,
        numberFormat,
        railGradient,
        ownValue,
        resultValue,
        setNewValue,
        handleDraggingProcess,
        onChangeTargetValue,
    } = useSlider(props)

    const [errorForEditInput, setErrorForEditInput] = useState<boolean>(false)
    const [edit, setEditMode] = useState<boolean>(false)
    const numberFormatResult = numberFormat
        ? resultValue.toFixed(numberFormat.length - numberFormat.indexOf('.') - 1)
        : resultValue

    const onChangeSliderMemo = useCallback(
        (_: Event, _value: number | number[]) => {
            const [first, second] = _value as number[]
            const [prevFirst, prevSecond] = ownValue as number[]

            if (first === 0) {
                return setNewValue(second, [0, second])
            }
            if (second === 0) {
                return setNewValue(first, [first, 0])
            }
            if (prevFirst === first && second !== prevSecond) {
                return setNewValue(second, [0, second])
            }
            if (prevFirst === 0 && first < 0) {
                return setNewValue(first, [first, 0])
            }
            if (prevSecond === second && first > 0) {
                return setNewValue(first, [0, first])
            }

            return setNewValue(second, [0, second])
        },
        [ownValue, setNewValue],
    )
    const onChangeInputMemo = useCallback(
        (value: number | string) => {
            const coercedValue = Number(value)
            const checkValue = isNaN(coercedValue)
            if (checkValue) return setErrorForEditInput(checkValue)

            onChangeTargetValue(coercedValue)
            setEditMode(false)
        },
        [onChangeTargetValue],
    )

    return (
        <Box
            sx={[
                {
                    color: 'text.secondary',
                    '&:hover .filter-title, &:hover .sliderUnstyledClasses': {
                        color: 'text.primary',
                        opacity: 1,
                    },
                },
                disableEffect && {
                    pointerEvents: 'none',
                    opacity: 0.2,
                },
            ]}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: '-5px',
                }}
            >
                <Typography
                    className="filter-title"
                    color={'text.secondary'}
                    sx={{
                        '& > sup': theme => ({ ...theme.typography.body2, color: 'text.secondary' }),
                    }}
                    component={'label'}
                    variant={'h3'}
                    htmlFor={id}
                >
                    {label}
                </Typography>
                {edit ? (
                    <StyledInput
                        autoFocus
                        defaultValue={numberFormatResult}
                        onKeyDown={ev => {
                            if (ev.key === 'Enter') {
                                ev.stopPropagation()
                                ev.preventDefault()
                                onChangeInputMemo((ev.target as HTMLInputElement).value)
                            }
                        }}
                        onBlur={ev => onChangeInputMemo((ev.target as HTMLInputElement).value)}
                        className={cls(errorForEditInput && classes.editInputError)}
                    />
                ) : (
                    <Typography
                        onDoubleClick={() => setEditMode(true)}
                        sx={{
                            '&:hover': {
                                cursor: 'col-resize',
                            },
                        }}
                        color={'text.secondary'}
                        className="filter-title"
                        component={'span'}
                        variant={'subtitle1'}
                    >
                        {numberFormatResult}
                    </Typography>
                )}
            </Box>
            <StyledSlider
                {...getTestId(id)}
                classes={{ root: 'sliderUnstyledClasses' }}
                defaultValue={defaultValue}
                getAriaLabel={() => ariaLabel}
                value={ownValue}
                onMouseDown={handleDraggingProcess(true)}
                onChangeCapture={handleDraggingProcess(true)}
                onChangeCommitted={handleDraggingProcess(false)}
                onChange={onChangeSliderMemo}
                className={resultValue < 0 ? classes.sliderNegative : classes.sliderPositive}
                min={min}
                max={max}
                componentsProps={{ input: { id } }}
                step={step}
                sx={[
                    !!railGradient && {
                        '.MuiSlider-rail': {
                            background: railGradient,
                            opacity: 1,
                        },
                        '.MuiSlider-track': {
                            background: 'transparent',
                        },
                    },
                    disableEffect && {
                        '.MuiSlider-rail': {
                            background: '#fff',
                        },
                        '.MuiSlider-track': {
                            background: 'transparent',
                        },
                    },
                ]}
            />
        </Box>
    )
}

const useSlider = (props: Props) => {
    const { disableEffect, defaultValue = VISUAL_DEFAULT_RANGE.visualMinValue, config, fieldForm } = props
    const {
        watch,
        field: { onChange, name },
    } = fieldForm
    const {
        name: label,
        visualValues = {
            visualMaxValue: VISUAL_DEFAULT_RANGE.visualMaxValue,
            visualMinValue: VISUAL_DEFAULT_RANGE.visualMinValue,
            visualStep: VISUAL_DEFAULT_RANGE.visualStep,
        },
        numberFormat = '',
        railGradient = '',
    } = config

    const {
        visualMinValue: min,
        visualMaxValue: max,
        visualStep: step = VISUAL_DEFAULT_RANGE.visualStep,
    } = visualValues

    const [ownValue, setValue] = useState<number | Array<number>>([0, 0])
    const [resultValue, setResultValue] = useState<number>(0)
    const { toolId } = useEffectInfo()
    const storeValue = useAppSelector(uiSelectors.getParameterState(toolId, name))
    const dragging = useRef(false)

    useEffect(() => {
        const sub = watch(status => {
            if (status === 'reset') {
                setValue([VISUAL_DEFAULT_RANGE.visualMinValue, VISUAL_DEFAULT_RANGE.visualMinValue])
                setResultValue(defaultValue)
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [defaultValue, watch])

    const setNewValue = useCallback(
        (value: number, values: number[]) => {
            onChange(value)
            setResultValue(value)
            setValue(values)
        },
        [onChange],
    )

    const onChangeTargetValue = useCallback(
        (value: number) => {
            if (Number.isNaN(value)) return

            let result: number[]
            if (value < min) {
                value = min
                result = [value, 0]
            } else if (value > max) {
                value = max
                result = [0, value]
            } else if (value < 0) {
                result = [value, 0]
            } else {
                result = [0, value]
            }
            setNewValue(value, result)
        },
        [min, max, setNewValue],
    )

    const handleDraggingProcess = (edge: boolean) => () => {
        dragging.current = edge
    }

    useEffect(() => {
        if (typeof storeValue === 'undefined') return
        if (dragging.current) return
        if (storeValue === resultValue) return
        onChangeTargetValue(Number(storeValue))
    }, [defaultValue, onChangeTargetValue, resultValue, storeValue])

    return {
        id: name,
        label,
        ariaLabel: `${toolId}-${name}`,
        onChange,
        disableEffect,
        defaultValue,
        min,
        max,
        step,
        numberFormat,
        railGradient,
        ownValue,
        resultValue,
        setNewValue,
        handleDraggingProcess,
        onChangeTargetValue,
    }
}

const StyledSlider = styled(SliderUnstyled)(
    () => `
    height: 3px;
    width: 100%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-top: 14px;
    &:after {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: calc(100% + 10px);
    },
  
    & .${sliderUnstyledClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background-color: ${theme.palette.primary.light};
      opacity: 0.38;
    }
  
    & .${sliderUnstyledClasses.track} {
      display: block;
      position: absolute;
      height: 3px;
      border-radius: 2px;
      background: linear-gradient(270deg, rgba(174, 78, 229, 0.5) -0.05%, rgba(106, 209, 249, 0.5) 100.64%);
    }
  
    & .${sliderUnstyledClasses.thumb} {
      position: absolute;
      width: 11px;
      height: 11px;
      margin-left: -4px;
      margin-top: -4px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      background-color: #fff;

      :hover,
      &.${sliderUnstyledClasses.focusVisible} {
        box-shadow: 0 0 0 0.25rem #333A4B;
      }
  
      &.${sliderUnstyledClasses.active} {
        box-shadow: 0 0 0 0.25rem #333A4B;
      }
    }
  `,
)

const StyledInput = styled(InputUnstyled)({
    [`& .${inputUnstyledClasses.input}`]: {
        display: 'inline-block',
        maxWidth: '37px',
        padding: '3px',
        borderRadius: '3px',
        textAlign: 'right',
        background: 'none',
        outline: 'none',
        border: '1px solid #368CFF',
        color: `${theme.palette.text.primary}`,
        height: '20px',
        '&::selection': {
            background: 'rgba(54, 140, 255, 0.5)',
        },
    },
})
