import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import { Typography, Button } from '@mui/material'
import { get } from 'lodash-es'
import React, { FC } from 'react'
import { EffectInfoInner, PayloadMapping } from './constants'
import { CropPayload } from '@features/toolsPanel/store/actions'
import { LumIcon } from '@shared/components/icons'
import { LabelGroup } from './LabelGroup'

type ButtonProps = {
    item: EffectInfoInner
    parameter: string
    field: UseFieldReturn
    onClick: (elType?: CropPayload['element']) => () => void
}

export const CropButton: FC<ButtonProps> = ({ item, parameter, onClick }) => {
    return (
        <>
            {!item.innerLabel && <LabelGroup>{item.name}</LabelGroup>}
            <Button
                size="small"
                type="button"
                color={parameter === 'Submit' ? 'secondary' : 'primary'}
                onClick={onClick(item.name ? PayloadMapping[item.name] : undefined)}
                fullWidth
            >
                {!!item.innerLabel ? (
                    <Typography
                        variant="h2"
                        component={'span'}
                        className="lumi-par"
                        sx={{
                            color: 'inherit',
                            position: 'relative',
                            lineHeight: '18px',
                            '& > sup': theme => ({
                                ...theme.typography.body2,
                                color: theme.palette.accent.third,
                                ml: '2px',
                                top: 0,
                            }),
                        }}
                        dangerouslySetInnerHTML={{ __html: item.name || '' }}
                    />
                ) : (
                    get(iconsMap, item?.iconName || '', null)
                )}
            </Button>
        </>
    )
}

const iconsMap = {
    alignment: <LumIcon as="horizontalAlignment" />,
}
