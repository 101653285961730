import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@store/configure-store'

const baseThumbPath = '/resources/SkyTextures/thumbs/'
const baseOriginalPath = '/resources/SkyTextures/'
const formLink = (path: string, suffix: string) => `${baseThumbPath}${path}/${suffix}`
const selectSelfTextures = (state: RootState) => state.textures

export const getSkyEntity = createSelector(selectSelfTextures, textures => textures.sky.payload)
export const getSkyKeys = createSelector(getSkyEntity, sky => ['All skies'].concat(Object.keys(sky)))
export const getFullLinks = createSelector(getSkyEntity, getSkyKeys, (parent, keys) =>
    keys.map(key => (parent[key] || []).map(suff => formLink(key, suff))),
)

export const getSkyAssets = createSelector(getFullLinks, allLinks => [
    allLinks.reduce((acc, val) => acc.concat(val), []),
    ...allLinks.slice(1),
])
export const getSkyCurrentCategory = createSelector(selectSelfTextures, textures => textures.sky.currentCategory || 0)
export const getSkyCurrentOptionId = createSelector(
    selectSelfTextures,
    getSkyAssets,
    (textures, assets): string => textures.sky.currentOption ?? assets[0][0],
)
// Uses only for MIPL
export const getCurrentOriginalSkyPath = createSelector(getSkyCurrentOptionId, (path): string =>
    path?.replace(baseThumbPath, baseOriginalPath),
)
export const isChoosedOption = createSelector(selectSelfTextures, (textures): boolean => !!textures.sky.currentOption)

export const skiesAreLoaded = createSelector(selectSelfTextures, textures => textures.loading === 'succeeded')

export const getSkyOptions = createSelector(getSkyKeys, sky => sky.map((key, idx) => ({ id: idx, title: key })))

export const getSkyCurrentList = createSelector(
    getSkyAssets,
    getSkyCurrentCategory,
    (_assets, optionId) => _assets[optionId] || [],
)
