import * as React from 'react'
import { Divider, SelectChangeEvent, Theme } from '@mui/material'
import Select, { SelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Box, SxProps } from '@mui/system'
import { LumIcon } from '@shared/components/icons'
import scssVars from '@styles/vars.module.scss'

type Option = {
    id: number | string
    title?: string
    divider?: boolean
}

type Props = {
    options: Option[]
    defaultValue?: Option['id']
    value?: Option['id']
    onChoose: (props: { id: Option['id'] }) => void
    onOpen?: (open: boolean) => void
    bgDarkNoBlur?: boolean
    displayEmpty?: boolean
    enableCheck?: boolean
    root?: SxProps<Theme>
    renderValue?: (value: string | number) => React.ReactNode
} & Omit<SelectProps, 'defaultValue' | 'value' | 'onOpen'>

export function DefaultSelect({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onOpen = () => {},
    options,
    onChoose,
    defaultValue,
    value,
    bgDarkNoBlur = false,
    enableCheck = true,
    root,
    renderValue,
    ...rest
}: Props) {
    const handleChoose = (event: SelectChangeEvent<number | string>) => {
        const value = event.target.value

        onChoose({ id: isNaN(Number(value)) ? value : Number(value) })
    }

    return (
        <Select
            fullWidth
            disableUnderline
            defaultValue={defaultValue}
            renderValue={renderValue}
            IconComponent={() => {
                return (
                    <Box
                        sx={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            position: 'absolute',
                            right: '2px',
                            pointerEvents: 'none',
                        }}
                    >
                        <LumIcon as="arrowDown" size={'1.25rem'} color={'text.primary'} />
                    </Box>
                )
            }}
            value={value}
            onOpen={() => onOpen(true)}
            onChange={handleChoose}
            sx={[
                {
                    '.MuiSelect-select': {
                        pl: '8px',
                        background: 'transparent',
                        '&:focused, &:hover': {
                            background: 'transparent',
                        },
                    },
                    background: 'btnPrimary.default',
                    color: 'text.secondary',
                    '&:hover': {
                        background: 'btnPrimary.over',
                    },
                    '&.Mui-focused': {
                        background: 'btnPrimary.pressed',
                        color: 'text.primary',
                    },
                    '&[disabled]': {
                        background: 'btnPrimary.disabled',
                    },
                },
                ...(Array.isArray(root) ? root : [root]),
            ]}
            MenuProps={{
                PaperProps: {
                    style: {
                        marginTop: '4px',
                        borderRadius: scssVars.shapeRadiusSmall,
                        background: bgDarkNoBlur ? '#2f2f2f' : 'primary.dark',
                        backdropFilter: bgDarkNoBlur ? 'blur(0)' : 'blur(51px)',
                        WebkitBackdropFilter: bgDarkNoBlur ? 'blur(0)' : 'blur(51px)',
                    },
                },
                MenuListProps: {
                    style: {
                        width: '100%',
                        padding: '4px 0',
                    },
                },
            }}
            {...rest}
        >
            {options.map(({ id, title, divider }) =>
                divider ? (
                    <Divider key={id} sx={{ p: '0', m: '9px 7px' }} />
                ) : (
                    <MenuItem
                        key={id}
                        value={id}
                        sx={{
                            color: 'secondary.contrastText',
                            p: '6px 19px',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                            '&.Mui-selected': {
                                position: 'relative',
                                backgroundColor: 'primary.light',
                                ...(enableCheck
                                    ? {
                                          '&:before': {
                                              fontFamily: 'Material Icons',
                                              content: '"done"',
                                              position: 'absolute',
                                              left: '4px',
                                              fontSize: '.825rem',
                                          },
                                      }
                                    : {}),
                            },
                        }}
                    >
                        {title}
                    </MenuItem>
                ),
            )}
        </Select>
    )
}
