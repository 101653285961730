import { ToolId } from '@domain/filters'
import { actions } from '@features/toolsPanel/store'
import { Accordion, AccordionDetails, AccordionSummary, Box, SxProps, Theme, Typography } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import { useAppDispatch } from '@store/hooks'
import React, { useCallback, useState } from 'react'

type Props = {
    children?: React.ReactNode
    title?: React.ReactNode | string
    toolId: ToolId
    name: string
}
const summarySX: SxProps<Theme> = {
    p: '0 13px 0 16px',
    minHeight: '40px',
    borderTop: theme => `1px solid ${theme.palette.text.disabled}`,
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-around',
    },
    '&:hover .lumicon-arrow_up, &:hover .lumi-par': {
        color: 'text.secondary',
    },
}

const defaultValue = false
export const FilterSubMenu: React.FC<Props> = ({ children, title, name, toolId }) => {
    const [open, setOpen] = useState<boolean>(defaultValue)
    const dispatch = useAppDispatch()

    const onOpenChange = useCallback(() => {
        setOpen(!open)
        dispatch(actions.onSubmenuToggle(name))
    }, [dispatch, name, open])
    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            onChange={onOpenChange}
            expanded={open}
            sx={{
                bgcolor: 'transparent',
                '&::before': {
                    opacity: 0,
                    border: 'none',
                },
            }}
        >
            <AccordionSummary sx={summarySX}>
                <Box
                    sx={{
                        display: 'flex',
                        alignContent: 'start',
                        flex: '1 0 auto',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h3" color={'text.secondary'} component={'span'} className="lumi-par">
                        {title}
                    </Typography>
                    <LumIcon
                        as="arrow_up"
                        size={'.8rem'}
                        rotate={open ? '0deg' : '180deg'}
                        color={'text.secondary'}
                        sx={{
                            float: 'right',
                            fontSize: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: '10px 32px', position: 'relative' }}>{children}</AccordionDetails>
        </Accordion>
    )
}
