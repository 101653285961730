import * as React from 'react'
import Box from '@mui/material/Box'
import { LumIcon } from '@shared/components/icons'
import { Button, ClickAwayListener, Typography } from '@mui/material'
import { PopperMenu } from './PopperMenu'
import { DefaultSelect } from '../DefaultSelect'
import Image from 'next/image'
import { ImagePlaceholder } from '../../Placeholder'
import { last } from 'lodash-es'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { fetchToolsPanel } from '@features/toolsPanel/store/actions'
import {
    getSkyCurrentCategory,
    getSkyCurrentList,
    getSkyCurrentOptionId,
    getSkyOptions,
    isChoosedOption,
    skiesAreLoaded,
} from '@features/toolsPanel/store/textures.selectors'
import { Textures } from '@features/toolsPanel/store/data/tools/store'
import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import { theme } from '@shared/components/providers'
import scssVars from '@styles/vars.module.scss'

type Props = {
    label?: React.ReactNode
    onChange?: (props: { category: number; path: string }) => void
    watchChanges: UseFieldReturn['watch']
}

export function SkySelect({ label, onChange, watchChanges }: Props): JSX.Element | null {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const refOpenSelect = React.useRef<boolean>(false)
    const open = Boolean(anchorEl)
    const {
        options,
        onFilter,
        currentList,
        currentCategory,
        currentOptionId,
        setCurrentOption,
        isListLoaded,
        isChoosedOption,
    } = useSelectAssets()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(!open ? event.currentTarget : null)
    }
    const handleClose = () => {
        if (refOpenSelect.current) return
        setAnchorEl(null)
    }
    const handleSelectAccessability = (isOpen: boolean) => {
        refOpenSelect.current = isOpen
    }

    const onSelectOption = React.useCallback(
        (path: string) => () => {
            setCurrentOption(path)
            onChange && onChange({ category: currentCategory, path })
        },
        [onChange, setCurrentOption, currentCategory],
    )
    watchChanges(status => {
        if (status === 'reset') {
            setCurrentOption(null)
            onFilter(null)
        }
    })
    const id = open ? 'sky-menu' : undefined
    const popperProps = { anchorEl, id }
    const optionName = isChoosedOption ? last(currentOptionId.split('/'))?.replace(/.jpg|.png/, '') : null

    return isListLoaded ? (
        <>
            <Button
                fullWidth
                aria-describedby={id}
                onClick={handleClick}
                sx={{
                    p: '22px 14px 22px 16px',
                    position: 'relative',
                    '&::after': {
                        content: '" "',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: theme => `1px solid  ${theme.palette.primary.dark}`,
                        borderRadius: theme => `${theme.shape.borderRadius}px`,
                    },
                    '&::before': {
                        content: '" "',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: scssVars.customThirdBackDarkColor,
                        backgroundBlendMode: 'normal',
                        zIndex: 1,
                        borderRadius: theme => `${theme.shape.borderRadius}px`,
                    },
                }}
            >
                <Image
                    src={currentOptionId}
                    style={{
                        borderRadius: `${theme.shape.borderRadius}px`,
                        zIndex: 0,
                        ...(!isChoosedOption && { filter: 'grayscale(1)' }),
                    }}
                    alt="current"
                    layout="fill"
                    objectFit="cover"
                    objectPosition={'center'}
                    placeholder="blur"
                    blurDataURL={bluredPlaceholder}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', zIndex: 1, width: '100%' }}>
                    <Typography variant="h3" component={'span'} color={'secondary.contrastText'}>
                        {optionName ? optionName : label || 'Sky Selection'}
                    </Typography>{' '}
                    <LumIcon
                        as="arrow_up"
                        rotate="180deg"
                        sx={{
                            color: theme => theme.palette.text.secondary,
                            fontSize: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                </Box>
            </Button>
            <PopperMenu {...popperProps}>
                <ClickAwayListener onClickAway={handleClose}>
                    <Box>
                        <Box sx={{ p: '11px 13px 0' }}>
                            <DefaultSelect
                                value={currentCategory}
                                onOpen={handleSelectAccessability}
                                onChoose={({ id }) => onFilter(id as number)}
                                options={options}
                                bgDarkNoBlur={true}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                gap: '12px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                p: '11px 12px',
                                maxHeight: '312px',
                                overflowY: 'auto',
                            }}
                        >
                            {currentList.map(path => (
                                <Button
                                    key={path}
                                    onClick={onSelectOption(path)}
                                    sx={{
                                        position: 'relative',
                                        width: '64px',
                                        height: '64px',
                                        boxShadow: 'inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                                        filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2))',
                                        borderRadius: theme => `${theme.shape.borderRadius}px`,
                                        '&:focus': {
                                            boxShadow: theme => `0 0 0 3px ${theme.palette.secondary.main}`,
                                        },
                                    }}
                                >
                                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                    <Image
                                        key={path}
                                        src={path}
                                        style={{
                                            borderRadius: `${theme.shape.borderRadius}px`,
                                        }}
                                        layout="fill"
                                        objectFit="cover"
                                        objectPosition={'center'}
                                        placeholder="blur"
                                        blurDataURL={bluredPlaceholder}
                                    />
                                </Button>
                            ))}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </PopperMenu>
        </>
    ) : null
}

const useSelectAssets = () => {
    const isListLoaded = useAppSelector(skiesAreLoaded)
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        if (isListLoaded) return
        dispatch(fetchToolsPanel.act())
    }, [dispatch, isListLoaded])

    return {
        options: useAppSelector(getSkyOptions),
        currentCategory: useAppSelector(getSkyCurrentCategory),
        isChoosedOption: useAppSelector(isChoosedOption),
        setCurrentOption: (id: string | null) => dispatch(Textures.actions.setCurrentOption(id)),
        currentOptionId: useAppSelector(getSkyCurrentOptionId),
        currentList: useAppSelector(getSkyCurrentList),
        onFilter: (id: number | null) => dispatch(Textures.actions.setCurrentCategory(id)),
        isListLoaded,
    }
}

const bluredPlaceholder = ImagePlaceholder(64, 64)
