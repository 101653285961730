import * as React from 'react'
import { Box } from '@mui/system'
import Tabs from '@mui/material/Tabs'
import scssVars from '@styles/vars.module.scss'
import { Tab } from '@mui/material'

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
    index: number
    value: number
}

type Props = {
    children: React.ReactNode[]
    groups: {
        title: string
    }[]
    defaultValue: number
    tabIcons?: JSX.Element[]
    onChange: (num: number) => void
}

export const DefaultTabs = ({ groups, children, defaultValue, tabIcons, onChange }: Props) => {
    const [value, setValue] = React.useState(defaultValue)

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        onChange(newValue)
    }

    return (
        <>
            <Tabs
                TabIndicatorProps={{ sx: { display: 'none' } }}
                value={value}
                onChange={handleChange}
                sx={[
                    {
                        border: theme => `1px solid ${theme.palette.primary.light}`,
                        borderRadius: scssVars.shapeRadiusSmall,
                        minHeight: '28px',
                        p: '1px',
                        mb: '14px',
                        position: 'relative',
                        transition: theme => theme.transitions.create(['background-color'], { duration: 300 }),
                        '&:hover': {
                            backgroundColor: theme => theme.palette.primary.dark,
                        },
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'space-between',
                        },
                        '& .MuiTab-root.Mui-selected': {
                            backgroundColor: theme => theme.palette.primary.light,
                        },
                    },
                ]}
                aria-label="basic tabs"
                defaultValue={defaultValue}
            >
                {tabIcons?.map((child, index: number) => {
                    return (
                        <Tab
                            key={`${groups[index].title}-${index}`}
                            sx={{
                                fontSize: scssVars.text1,
                                fontWeight: 400,
                                p: '3px',
                                borderRadius: '2px',
                                height: '100%',
                                minHeight: '24px',
                                minWidth: 'calc(50% - 1px)',
                                transition: theme => theme.transitions.create(['background-color'], { duration: 300 }),
                                '&:hover': {
                                    backgroundColor: scssVars.textActiveColor2,
                                },
                            }}
                            icon={child}
                            {...a11yProps(index)}
                        ></Tab>
                    )
                })}
            </Tabs>
            {children.map((child, index) => {
                return (
                    <TabPanel key={groups[index].title} value={value} index={index}>
                        {child}
                    </TabPanel>
                )
            })}
        </>
    )
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box
            role="tabEffect"
            hidden={value !== index}
            id={`simple-tabEffect-${index}`}
            aria-labelledby={`simple-tab-effect-${index}`}
            {...other}
        >
            {children}
        </Box>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-effect-${index}`,
        'aria-controls': `simple-tabEffect-${index}`,
    }
}
