import * as React from 'react'
import { Box } from '@mui/system'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import scssVars from '@styles/vars.module.scss'
import { theme } from '@shared/components/providers'

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
    index: number
    value: number
}

type Props = {
    children?: React.ReactNode
    groups: {
        title: string
        iconName?: string
    }[]
    defaultValue: number
}

export const SelectTabs = ({ groups, children, defaultValue }: Props) => {
    const [value, setValue] = React.useState<number>(defaultValue)

    const handleChoose = (event: SelectChangeEvent) => {
        const val = event.target.value
        setValue(Number(val))
    }

    return (
        <Box sx={{ mb: '26px' }}>
            <Select
                fullWidth
                disableUnderline
                displayEmpty
                IconComponent={() => {
                    return (
                        <Box
                            sx={{
                                display: 'inline-flex',
                                flexDirection: 'column',
                                position: 'absolute',
                                right: '2px',
                                pointerEvents: 'none',
                            }}
                        >
                            <LumIcon as="arrowDown" size={'1.25rem'} color={theme.palette.text.primary} />
                        </Box>
                    )
                }}
                value={String(value)}
                onChange={handleChoose}
                sx={{
                    mb: '14px',
                }}
            >
                {groups.map((item, idx) => {
                    if (!item.iconName) return
                    return (
                        <MenuItem
                            key={idx}
                            value={idx}
                            sx={{
                                color: 'secondary.contrastText',
                                p: '6px 8px',
                                '&:hover': {
                                    backgroundColor: theme => theme.palette.primary.dark,
                                },
                                '&.Mui-selected': {
                                    position: 'relative',
                                    backgroundColor: theme => theme.palette.primary.light,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: '14px',
                                    maxWidth: '14px',
                                    height: '14px',
                                    mr: '5px',
                                    borderRadius: '50%',
                                    background: `${scssVars[item.iconName]}`,
                                }}
                            ></Box>
                            {item.title}
                        </MenuItem>
                    )
                })}
            </Select>
            {(children as React.ReactNode[]).map((child, index) => {
                return (
                    <TabPanel key={groups[index].title} value={value} index={index}>
                        {child}
                    </TabPanel>
                )
            })}
        </Box>
    )
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box role="tabHsl" hidden={value !== index} id={`tabHsl-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {children}
        </Box>
    )
}
