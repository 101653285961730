import React, { useEffect, useState } from 'react'
import { Box, Button as MuiButton, ButtonProps } from '@mui/material'
import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import scssVars from '@styles/vars.module.scss'
type Props = {
    fieldForm: UseFieldReturn
    label: React.ReactNode
    defaultValue?: number
} & ButtonProps

export const BWToggleButton: React.FC<Props> = props => {
    const {
        label,
        fieldForm: { field, watch },
        defaultValue,
    } = props

    const [disableButton, setDisableButton] = useState<boolean>(false)

    const handleButtonPress = () => {
        field.onChange && field.onChange(defaultValue)
        setDisableButton(!disableButton)
    }

    useEffect(() => {
        const sub = watch(status => {
            if (status === 'reset') {
                setDisableButton(false)
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [watch])

    return (
        <Box
            sx={[
                {
                    position: 'relative',
                    '&:after': {
                        content: '" "',
                        position: 'absolute',
                        width: '100%',
                        height: '28px',
                        top: 'calc(100% + 12px)',
                        left: 0,
                        zIndex: 1,
                    },
                },
                disableButton && {
                    '&:after': {
                        display: 'none',
                    },
                },
            ]}
        >
            <MuiButton
                size="small"
                sx={[
                    {
                        width: '100%',
                    },
                    disableButton && {
                        pointerEvents: 'none',
                        color: scssVars.textActiveColor2,
                        '&:active, &:focus': {
                            color: scssVars.textActiveColor2,
                        },
                    },
                ]}
                onClick={handleButtonPress}
            >
                {label}
            </MuiButton>
        </Box>
    )
}
