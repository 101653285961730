import * as React from 'react'
import Box from '@mui/material/Box'
import MuiPopper from '@mui/material/Popper'
import { styled } from '@mui/system'
import classes from './index.module.scss'

const anchorOffset = 3
const Popper = styled(MuiPopper)(() => ({
    zIndex: 1,
    '& > div': {
        position: 'relative',
    },
    '&[data-popper-placement*="bottom"]': {
        '& > div': {
            marginTop: anchorOffset,
        },
    },
    '&[data-popper-placement*="top"]': {
        '& > div': {
            marginBottom: anchorOffset,
        },
    },
    '&[data-popper-placement*="right"]': {
        '& > div': {
            marginLeft: anchorOffset,
        },
    },
    '&[data-popper-placement*="left"]': {
        '& > div': {
            marginRight: anchorOffset,
        },
    },
}))

type Props = {
    children: JSX.Element
    anchorEl: HTMLElement | null
    id: string | undefined
}

export const PopperMenu = ({ children, anchorEl, id }: Props) => {
    const open = Boolean(anchorEl)

    return (
        <Popper placement="bottom" id={id} open={open} anchorEl={anchorEl} disablePortal>
            <Box className={classes.dropdownMenu}>{children}</Box>
        </Popper>
    )
}
