import React, { useCallback } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { getCategories } from './store/data/tools/selectors'
import { editsSelectors, uiSelectors, store } from './store'
import { Category } from './components/Category'
import { EntityCategories } from './models'
import { ToolsFabricUI } from './components/ToolsFabricUI'
import { selectors } from '@features/renderer/store'
import { getLoaderLuminar } from '@store/selectors.ui'
import { theme } from '@shared/components/providers'
import { EditList } from './components/EditList'
import { Tabs as TTabs } from './types'

export { GeneralToolbar } from './components/GeneralToolbar'

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
    index: number
    value: number
}

const indexesToTabs: [TTabs, TTabs] = ['tools', 'edits']
const tabsToIndexes = indexesToTabs.reduce((acc, key, idx) => ({ ...acc, [key]: idx }), {} as Record<TTabs, number>)

export function ToolsPanel() {
    const { categories } = useConnect()
    const dispatch = useAppDispatch()
    const fileAdded = useAppSelector(selectors.isImageInfoFetched)
    const editsExists = useAppSelector(editsSelectors.isEditsExists)
    const currentTab = useAppSelector(uiSelectors.getCurrentTab)
    const isLoadingLoaderLuminar = useAppSelector(getLoaderLuminar)

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            const currentTab = indexesToTabs[newValue]
            if (currentTab === 'edits' && !editsExists) return
            dispatch(store.panelUISlice.actions.switchTab(currentTab))
        },
        [dispatch, editsExists],
    )

    return (
        <Box
            sx={[
                {
                    transform: 'translate(120%, -10%) scale(1.2)',
                    transition: `${theme.transitions.create(['opacity', 'transform'], {
                        duration: 600,
                        easing: theme.transitions.easing.easeOut,
                    })}`,
                },
                !fileAdded && {
                    opacity: '.4',
                    pointerEvents: 'none',
                },
                !isLoadingLoaderLuminar && {
                    transform: 'translate(0%, 0%) scale(1)',
                },
            ]}
        >
            <Tabs
                TabIndicatorProps={{ sx: { display: 'none' } }}
                value={tabsToIndexes[currentTab]}
                onChange={handleChange}
                sx={{
                    '& .MuiTab-root': {
                        color: 'text.secondary',
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: 'text.primary',
                    },
                }}
                aria-label="Applying effects tabs"
            >
                <Tab
                    sx={{
                        p: '9px',
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        minHeight: 'auto',
                        minWidth: 'auto',
                    }}
                    label="Tools"
                    {...a11yProps(currentTab)}
                />
                <Tab
                    sx={{
                        p: '9px 9px 9px 4px',
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        minHeight: 'auto',
                        minWidth: 'auto',
                    }}
                    data-available={editsExists}
                    label={
                        <Box
                            component={'span'}
                            sx={
                                editsExists
                                    ? {
                                          position: 'relative',
                                          '&:after': {
                                              content: '""',
                                              display: 'inline-block',
                                              position: 'absolute',
                                              borderRadius: '50%',
                                              width: '5px',
                                              height: '5px',
                                              top: '0px',
                                              right: '-6px',
                                              bgcolor: 'currentcolor',
                                          },
                                      }
                                    : {}
                            }
                        >
                            Edits
                        </Box>
                    }
                    {...a11yProps(currentTab)}
                />
            </Tabs>
            <TabPanel
                value={tabsToIndexes[currentTab]}
                index={tabsToIndexes['tools']}
                style={{ height: 'calc(100vh - 112px)', overflowY: 'auto', paddingBottom: '64px' }}
            >
                {categories.map(({ identifier, title, items }) => (
                    <Category key={identifier} id={identifier} title={title}>
                        {items.map(id => (
                            <ToolsFabricUI key={id} id={id} category={identifier} />
                        ))}
                    </Category>
                ))}
            </TabPanel>
            <TabPanel value={tabsToIndexes[currentTab]} index={tabsToIndexes['edits']}>
                <EditList />
            </TabPanel>
        </Box>
    )
}

const useConnect = (): { categories: EntityCategories[] } => {
    const categories = useAppSelector(getCategories)

    return { categories }
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    )
}

function a11yProps(name: TTabs) {
    return {
        id: `simple-tab-${name}`,
        'aria-controls': `simple-tabpanel-${name}`,
    }
}
