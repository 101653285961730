import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material'
import React, { FC } from 'react'
import { UseFieldReturn } from '@features/toolsPanel/hooks/form'

type Props = {
    checkbox?: CheckboxProps
    control?: FormControlLabelProps['control']
    fieldForm: UseFieldReturn
    disableEffect?: boolean
} & Omit<FormControlLabelProps, 'control'>

export const Checkbox: FC<Props> = ({ checkbox, fieldForm: { field }, disableEffect, ...rest }) => {
    return (
        <FormControlLabel
            sx={[
                {
                    ml: 0,
                    '& .MuiFormControlLabel-label': { ml: '8px' },
                    '&:hover .MuiFormControlLabel-label, & .Mui-checked ~ .MuiFormControlLabel-label': {
                        color: 'text.secondary',
                    },
                    '&.MuiFormControlLabel-root .MuiSvgIcon-root': {
                        fontSize: '1rem',
                    },
                },
                !!disableEffect && {
                    pointerEvents: 'none',
                    opacity: 0.2,
                },
            ]}
            componentsProps={typographyProps}
            {...rest}
            control={
                <MuiCheckbox size="small" inputRef={field?.ref} checked={!!field?.value} {...field} {...checkbox} />
            }
        />
    )
}

const typographyProps: FormControlLabelProps['componentsProps'] = { typography: { variant: 'h3' } }
