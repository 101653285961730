import { ToolColorByCategory } from '@features/toolsPanel/models'
import { Accordion, AccordionDetails, AccordionSummary, Box, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import React, { MouseEventHandler, useCallback, useMemo } from 'react'
import { get as _get } from 'lodash-es'
import { getTestId } from '@shared/testing'
import { WithRequired } from '@shared/utility/type'

type Props = {
    children?: React.ReactNode
    isApplied?: boolean
    isExpanded?: boolean
    color?: ToolColorByCategory
    title?: React.ReactNode
    testId?: string
    Icon: (props: { color: string }) => JSX.Element
    onResetClick?: () => void
    onOpen: () => void
    onCompare: (val: boolean) => void
    isOpen: boolean
    isCompareEnabled?: boolean
}

type RequiredField<T extends keyof Props> = WithRequired<Props, T>[T]

type IconTypes = 'iconPrimary' | 'iconSecondary' | 'iconThird' | 'iconFourth' | 'iconAccent' | 'none'

const iconsSX: SxProps<Theme> = {
    display: 'inline-block',
    fontSize: '1.125rem',
    ml: '.5rem',
    transition: theme => theme.transitions.create(['color'], { duration: 300 }),
    '&:hover': {
        color: 'secondary.contrastText',
    },
    '&:active': {
        color: 'primary.contrastText',
    },
}

export const FilterWrapper: React.FC<Props> = ({
    color = 'primary',
    title = '',
    testId = '',
    isExpanded = false,
    Icon,
    children,
    isApplied,
    onResetClick,
    onCompare,
    onOpen,
    isOpen,
    isCompareEnabled = true,
}) => {
    const onIconClick = useCallback<MouseEventHandler>(e => e.stopPropagation(), [])
    const currentTheme = useTheme()
    const getIconAccentColor = getIconPallete(currentTheme)
    const onResetClickMemo = useCallback(() => {
        onResetClick && onResetClick()
    }, [onResetClick])

    const accordionSX = useMemo<SxProps<Theme>>(
        () => ({
            background: 'transparent',
            '&:before': {
                content: 'none',
            },
            '&::after': {
                content: '" "',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: theme => `1px solid  ${theme.palette.primary.dark}`,
                borderRadius: theme => `${theme.shape.borderRadius}px`,
                pointerEvents: 'none',
                opacity: 0,
                transition: theme => theme.transitions.create(['opacity'], { duration: 400 }),
            },
            ...(isOpen
                ? {
                      mb: '16px',
                      position: 'relative',
                      '&.Mui-expanded:last-child': {
                          mb: '16px',
                      },
                      '&.Mui-expanded::after': {
                          opacity: 1,
                      },
                  }
                : {}),
        }),
        [isOpen],
    )
    const summarySXMemo = useMemo<SxProps<Theme>>(
        () => ({
            ...summarySX,
            '&.MuiAccordionSummary-root': {
                '&::after': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: getColorVariant(color, 'header'),
                    borderRadius: theme => `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                    pointerEvents: 'none',
                    opacity: 0,
                    transition: theme => theme.transitions.create(['opacity'], { duration: 400 }),
                },
            },
        }),
        [color],
    )

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            onChange={onOpen}
            expanded={isOpen}
            defaultExpanded={isExpanded}
            color="primary"
            sx={accordionSX}
        >
            <AccordionSummary {...getTestId(`${testId}-tool-title`)} sx={summarySXMemo}>
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignContent: 'start',
                        flex: '1 0 auto',
                    }}
                >
                    <Icon color={isOpen ? currentTheme.palette.text.primary : getIconAccentColor(color)} />
                    <Typography
                        variant="h3"
                        component={'span'}
                        className="lumi-par"
                        sx={{
                            position: 'relative',
                            '& > sup': theme => ({
                                ...theme.typography.body2,
                                color: theme.palette.accent.third,
                                ml: '1px',
                                top: 0,
                            }),
                        }}
                    >
                        {title}
                    </Typography>
                    {isOpen && isApplied && (
                        <Box
                            onClick={onIconClick}
                            sx={{
                                justifySelf: 'flex-end',
                                alignSelf: 'flex-end',
                                ml: 'auto',
                                height: '20px',
                            }}
                        >
                            {isCompareEnabled && (
                                <LumIcon
                                    {...getTestId('tool-compare')}
                                    as="compare"
                                    onMouseDown={() => onCompare(true)}
                                    onMouseUp={() => onCompare(false)}
                                    sx={iconsSX}
                                />
                            )}
                            <LumIcon {...getTestId('tool-reset')} onClick={onResetClickMemo} as="reset" sx={iconsSX} />
                        </Box>
                    )}
                </Box>

                <LumIcon
                    as="arrow_up"
                    size={'.5rem'}
                    rotate="180deg"
                    sx={{ float: 'right', display: 'flex', alignItems: 'center' }}
                />
            </AccordionSummary>
            <AccordionDetails
                {...getTestId(`${testId}-tool-summary`)}
                sx={{ ...detailsSX, background: getColorVariant(color, 'body') }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

const summarySX: SxProps<Theme> = {
    position: 'relative',
    p: '0 8px',
    minHeight: '40px',
    background: 'transparent',
    transition: theme => theme.transitions.create(['background', 'min-height'], { duration: 300 }),
    borderRadius: theme => `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-around',
        position: 'relative',
        zIndex: 1,
        margin: '10px 0',
        '&:hover .lumicon-arrow_up, &:hover .lumi-par': {
            opacity: 1,
        },
        '&:hover .lumicon-arrow_up': {
            transition: theme => theme.transitions.create(['opacity'], { duration: 0 }),
        },
    },
    '&.Mui-expanded .MuiAccordionSummary-content': {
        '.lumicon-arrow_up': {
            position: 'absolute',
            opacity: 0,
        },
        '.lumi-par': {
            color: 'text.primary',
        },
    },
    '& .lumi-par': {
        color: 'text.secondary',
        transition: theme => theme.transitions.create(['color'], { duration: 300 }),
    },
    '& .lumicon-arrow_up': {
        opacity: 0,
        transition: theme => theme.transitions.create(['opacity'], { duration: 200 }),
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
        '&::after': {
            opacity: 1,
            transition: theme => theme.transitions.create(['opacity'], { duration: 100 }),
        },
    },
}

const detailsSX: SxProps<Theme> = {
    p: 0,
    borderRadius: theme => `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    '.lumi-par, .lumicon-arrow_up': {
        transition: theme => theme.transitions.create(['color'], { duration: 300 }),
    },
    '.lumicon-arrow_up': {
        fontWeight: 700,
    },
}

const getColorVariant = (variant: RequiredField<'color'>, element: keyof typeof primary): string | undefined =>
    _get(colorVariant, [variant, element], 'none')

const getIconPallete =
    (theme: Theme) =>
    (variant: RequiredField<'color'>, index = 0) =>
        _get(theme, ['palette', iconColorMapping[variant], index], theme.palette.text.primary).split(',')[0]

const primary = {
    header: 'linear-gradient(105.75deg, #005671 11%, #2A3662 89%)',
    body: 'linear-gradient(105.75deg, #01222D 11%, #0C172A 89%)',
} as const
const secondary = {
    header: 'linear-gradient(113.23deg, rgba(255, 65, 199, 0.22) 0%, rgba(229, 114, 78, 0.22) 100%)',
    body: 'linear-gradient(117.69deg, rgba(179, 75, 149, 0.16) 6.6%, rgba(200, 109, 81, 0.15) 100%)',
} as const
// Unstyled
const third = {
    header: 'linear-gradient(113.23deg, rgba(255, 65, 199, 0.22) 0%, rgba(229, 114, 78, 0.22) 100%)',
    body: 'linear-gradient(117.69deg, rgba(179, 75, 149, 0.16) 6.6%, rgba(200, 109, 81, 0.15) 100%)',
} as const
const fourth = {
    header: 'linear-gradient(113.23deg, rgba(255, 65, 199, 0.22) 0%, rgba(229, 114, 78, 0.22) 100%)',
    body: 'linear-gradient(117.69deg, rgba(179, 75, 149, 0.16) 6.6%, rgba(200, 109, 81, 0.15) 100%)',
} as const
const fifth = {
    header: 'linear-gradient(105.75deg, #2F2E2D 11%, #2E2D2C 89%)',
    body: 'linear-gradient(105.75deg, #242322 11%, #252423 89%)',
} as const
const accent = {
    header: 'linear-gradient(113.23deg, rgba(255, 65, 199, 0.22) 0%, rgba(229, 114, 78, 0.22) 100%)',
    body: 'linear-gradient(117.69deg, rgba(179, 75, 149, 0.16) 6.6%, rgba(200, 109, 81, 0.15) 100%)',
} as const

const colorVariant: Record<RequiredField<'color'>, { header: string; body: string }> = {
    primary,
    secondary,
    third,
    fourth,
    fifth,
    accent,
    none: accent,
}

const iconColorMapping: Record<RequiredField<'color'>, IconTypes> = {
    primary: 'iconPrimary',
    secondary: 'iconSecondary',
    third: 'iconThird',
    fourth: 'iconFourth',
    fifth: 'none',
    accent: 'iconAccent',
    none: 'none',
}
