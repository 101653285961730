import { actions, editsSelectors } from '@features/toolsPanel/store'
import { Box, SxProps, Theme } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { DefaultSelect } from '../Effects/DefaultSelect'
import scssVars from '@styles/vars.module.scss'
import { ScaleOptions, ScaleOptionsList } from '@features/toolsPanel/constants'
import { getTestId } from '@shared/testing'
import { uiSelectors } from '@features/renderer/store'
import { zoomImage } from '@features/toolsPanel/store/actions'
import { ScaleIds } from '@features/toolsPanel/types'
import { useActionsListener } from '@shared/hooks'

const iconsSX: SxProps<Theme> = {
    display: 'inline-block',
    fontSize: '1.125rem',
    ml: '.5rem',
    cursor: 'pointer',
    p: '4px',
    border: '1px solid transparent',
    transition: theme => theme.transitions.create(['color'], { duration: 100 }),
    borderRadius: theme => `${Number(theme.shape.borderRadius) / 2}px`,
    '&:hover': {
        color: 'secondary.contrastText',
        bgcolor: 'primary.main',
    },
    '&:active': {
        color: 'primary.contrastText',
        bgcolor: 'primary.main',
    },
    '&:focus-visible, &:focus': {
        border: theme => `1px solid ${theme.palette.focusColor}`,
        outline: 'none',
    },
    '&[aria-disabled="true"]': {
        color: scssVars.textActiveColor,
        cursor: 'auto',
        bgcolor: 'transparent',
    },
}

const ids: ScaleIds[] = [25, 50, 100, 200, 300, 600, 1200, 2400, 3000]

export const GeneralToolbar = () => {
    const dispatch = useAppDispatch()
    const edits = useAppSelector(editsSelectors.isEditsExists)
    const imageScale = useAppSelector(uiSelectors.getImageScale)
    const [scale, setScale] = useState<ScaleIds | undefined>(0)

    useActionsListener(({ payload }) => {
        if (scale === payload) return
        setScale(payload)
    }, zoomImage)

    useEffect(() => {
        if (!Number.isNaN(imageScale) && !ids.includes((imageScale * 100) as ScaleIds)) setScale(undefined)
    }, [imageScale])

    const renderValue = useCallback(
        (val: unknown) => {
            if (val == 0 || val === undefined) return `${Math.floor(imageScale * 100)}%`

            const option = (ScaleOptionsList as any)[val as string]
            if (!option) return null
            return option.title
        },
        [imageScale],
    )

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px',
                background: 'rgba(0, 0, 0, 0.6)',
                backdropFilter: 'blur(7px)',
                borderRadius: theme => `${theme.shape.borderRadius}px`,
                border: theme => `1px solid ${theme.palette.primary.dark}`,
            }}
        >
            <LumIcon
                onMouseDown={() => dispatch(actions.onDisableAllEdits(true))}
                onMouseUp={() => dispatch(actions.onDisableAllEdits(false))}
                aria-disabled={!edits}
                tabIndex={0}
                viewBox="0 0 20 20"
                sx={[
                    iconsSX,
                    {
                        ml: 0,
                    },
                ]}
                as="compare"
                title="Preview"
                {...getTestId('compare-all')}
            />
            <Box
                sx={{
                    width: '1px',
                    height: '20px',
                    m: '0 5px',
                    bgcolor: theme => theme.palette.primary.main,
                }}
            />
            <DefaultSelect
                {...getTestId('scale-options')}
                onChoose={({ id }) => dispatch(zoomImage(id as ScaleIds))}
                root={{
                    bgcolor: 'transparent',
                }}
                bgDarkNoBlur
                displayEmpty
                enableCheck={false}
                value={scale}
                options={ScaleOptions}
                renderValue={renderValue}
            />
        </Box>
    )
}
