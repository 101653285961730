import { editsSelectors, actions } from '@features/toolsPanel/store'
import { toolIdToicon } from '@features/toolsPanel/utils/toolIdToicon'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { LumIcon } from '@shared/components/icons'
import { EditsInfo } from '@shared/constants/editsInfo'
import { getTestId } from '@shared/testing'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useCallback } from 'react'

type Props = {
    id: string
}

const toggleCurrentStyle = (current = false): SxProps<Theme> =>
    current
        ? {
              bgcolor: 'primary.light',
              '&  span, svg': {
                  color: 'primary.contrastText',
              },
          }
        : {
              bgcolor: 'primary.main',
              '&  span, svg': {
                  color: 'secondary.contrastText',
              },
          }
const toggleActiveStyle = (active = false): SxProps<Theme> =>
    active
        ? {}
        : {
              bgcolor: 'btnPrimary.disabled',
              '&  span, svg': {
                  color: 'primary.light',
              },
          }

const iconsSX: SxProps<Theme> = {
    display: 'inline-block',
    fontSize: '1.125rem',
    ml: '.5rem',
    cursor: 'pointer',
    transition: theme => theme.transitions.create(['color'], { duration: 300 }),
    '&:hover': {
        color: 'secondary.contrastText',
    },
    '&:active': {
        color: 'primary.contrastText',
    },
}

export const EditListItem = ({ id }: Props) => {
    const edit = useAppSelector(state => editsSelectors.getEditById(state, id))
    const dispatch = useAppDispatch()
    const onChoose = useCallback(() => !edit?.isCurrent && dispatch(actions.setCurrentEdit(id)), [dispatch, edit, id])

    if (!edit) return null

    return (
        <Box
            {...getTestId(`edit-${edit.toolName}`)}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: '8px',
                borderRadius: theme => `${theme.shape.borderRadius as number}px`,
                ...({ ...toggleCurrentStyle(edit.isCurrent), ...toggleActiveStyle(edit.active) } as any),
                '&:hover': {
                    bgcolor: 'btnPrimary.over',
                },
            }}
            onClick={onChoose}
        >
            <Box
                sx={{
                    display: 'inline-flex',
                    cursor: 'pointer',
                    flex: '1 0 auto',
                    padding: '12px 9px',
                }}
            >
                <LumIcon as={toolIdToicon[edit.toolName]} size={'1.25rem'} sx={{ m: '0 4px 0 0' }} />
                <Typography
                    variant="h2"
                    component={'span'}
                    className="lumi-par"
                    sx={{
                        position: 'relative',
                        display: 'inline-block',
                        '& > sup': theme => ({
                            ...theme.typography.body2,
                            color: theme.palette.accent.third,
                            ml: '1px',
                            top: 0,
                        }),
                    }}
                >
                    {(EditsInfo as any)[edit.toolName]?.title || ''}
                </Typography>
            </Box>
            {edit.isCurrent && (
                <Box sx={{ padding: '12px' }}>
                    <LumIcon
                        as="compare"
                        onMouseDown={event => {
                            event.stopPropagation()
                            dispatch(actions.disableCurrentEdit({ id: edit.toolName, disable: true }))
                        }}
                        onMouseUp={event => {
                            event.stopPropagation()
                            dispatch(actions.disableCurrentEdit({ id: edit.toolName, disable: false }))
                        }}
                        sx={iconsSX}
                    />
                    <span
                        onClick={event => {
                            event.stopPropagation()
                            dispatch(actions.removeCurrentEdit(edit.toolName))
                        }}
                    >
                        <LumIcon as="remove" sx={iconsSX} />
                    </span>
                </Box>
            )}
        </Box>
    )
}
