import React from 'react'
import { Typography } from '@mui/material'
type Props = {
    label: React.ReactNode
    disableEffect?: boolean
}

export const TabButtonIcons = ({ label, disableEffect }: Props) => {
    return (
        <Typography variant="body1" sx={[!!disableEffect && { opacity: 0.2 }]}>
            {label}
        </Typography>
    )
}
