import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import { Box, Button, SxProps } from '@mui/material'
import React, { FC } from 'react'
import { EffectInfoInner, PayloadMapping } from './constants'
import { FlipRounded, Rotate90DegreesCwOutlined } from '@mui/icons-material'
import { CropPayload } from '@features/toolsPanel/store/actions'
import { LabelGroup } from './LabelGroup'

const btnSX: SxProps = {
    flex: '1 0 auto',
    borderRadius: '4px',
    p: '2px 0',
}
const iconsSX: SxProps = { fontSize: '1.25rem', color: 'inherit' }

type ButtonProps = {
    item: EffectInfoInner
    parameter: string
    field: UseFieldReturn
    onClick: (elType?: CropPayload['element']) => () => void
}
export const ButtonGroup: FC<ButtonProps> = ({ item, onClick }) => {
    return (
        <>
            <LabelGroup>{item.name}</LabelGroup>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& > button': {
                        mr: '2%',
                        '&:last-child': {
                            mr: 0,
                        },
                    },
                }}
            >
                <Button sx={btnSX} size="small" type="button" onClick={onClick(PayloadMapping['rotate'])}>
                    <Rotate90DegreesCwOutlined sx={{ ...iconsSX, transform: 'scaleX(-1)' }} />
                </Button>
                <Button sx={btnSX} size="small" type="button" onClick={onClick(PayloadMapping['hflip'])}>
                    <FlipRounded sx={iconsSX} />
                </Button>
                <Button sx={btnSX} size="small" type="button" onClick={onClick(PayloadMapping['vflip'])}>
                    <FlipRounded sx={{ ...iconsSX, transform: 'rotate(90deg)' }} />
                </Button>
            </Box>
        </>
    )
}
