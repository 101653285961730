import { CategoryId } from '@domain/filters'
import { Accordion, AccordionDetails, AccordionSummary, SxProps, Theme, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { LumIcon } from '@shared/components/icons'

type Props = {
    id: CategoryId
    title: string
    children: React.ReactNode
}

const TEMPORARY_EXCLUDED_CATEGORIES: string[] = ['Portrait', 'Pro', 'Creative']

const titleSX: SxProps<Theme> = {
    paddingLeft: '8px',
    letterSpacing: '0.08em',
    color: 'text.secondary',
    transition: theme => theme.transitions.create(['color'], { duration: 300 }),
    '&:hover, &:active, .Mui-expanded &': {
        color: 'text.primary',
    },
}

export const Category: React.FC<Props> = ({ title, children, id }) => {
    const [open, setOpen] = useState<boolean>(true)
    if (TEMPORARY_EXCLUDED_CATEGORIES.includes(id)) return null

    return id ? (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            onChange={() => setOpen(!open)}
            expanded={open}
            sx={{
                '&:before': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary sx={summarySX}>
                <Box>
                    <Typography sx={titleSX} variant="subtitle1">
                        {title}
                    </Typography>
                </Box>
                <LumIcon
                    as="arrow_up"
                    size={'.5rem'}
                    rotate={open ? '0deg' : '180deg'}
                    sx={{ float: 'right', display: 'flex', alignItems: 'center' }}
                />
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
        </Accordion>
    ) : (
        <Box
            sx={{
                mb: '8px',
                position: 'relative',
                '&:after': {
                    content: '" "',
                    position: 'absolute',
                    left: '8px',
                    bottom: '0',
                    right: '8px',
                    height: '1px',
                    backgroundColor: '#656464',
                    zIndex: -1,
                },
            }}
        >
            <Typography sx={titleSX} variant="h3">
                {title}
            </Typography>
            {children}
        </Box>
    )
}

const summarySX: SxProps<Theme> = {
    p: 0,
    minHeight: '40px',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    '& .MuiAccordionSummary-content': {
        m: '10px 0',
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        '&:hover .lumicon-arrow_up, &:hover .lumi-par': {
            color: 'text.secondary',
            opacity: 1,
        },
        '&:hover .lumicon-arrow_up': {
            transition: theme => theme.transitions.create(['opacity'], { duration: 0 }),
        },
    },
    '&.Mui-expanded .MuiAccordionSummary-content': {
        '.lumicon-arrow_up': {},
        '.lumi-par': {
            color: 'text.secondary',
        },
    },
    '& .lumi-par': {
        transition: theme => theme.transitions.create(['color'], { duration: 300 }),
    },
    '& .lumicon-arrow_up': {
        opacity: 0,
        transition: theme => theme.transitions.create(['opacity'], { duration: 200 }),
    },
}
