import { Box, Button } from '@mui/material'
import React from 'react'
import { DefaultSelect } from '../../../Effects/DefaultSelect'
import ImageAspectRatioRounded from '@mui/icons-material/ImageAspectRatioRounded'
import { EffectInfoInner } from '../constants'
import { UseFieldReturn } from '@features/toolsPanel/hooks/form'
import { Option } from './types'

type Props = {
    item: EffectInfoInner
    field: UseFieldReturn
    options: Option[]
    onChange: (val: { id: string | number }) => void
    onClick: () => void
    defaultValue?: string
}

export const RatioSelect = ({ options, onChange, onClick, field: { field } }: Props) => {
    return (
        <Box sx={{ display: 'flex', mb: '8px' }}>
            <DefaultSelect name={field.name} options={options} value={field.value?.id} onChoose={onChange} />
            <Button size="small" type="button" sx={{ minWidth: '24px', ml: '4px', p: '2px 0' }} onClick={onClick}>
                <ImageAspectRatioRounded sx={{ fontSize: '1.25rem', color: 'inherit' }} />
            </Button>
        </Box>
    )
}
